import { Fragment } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { Owners } from '../Owners'
import { useApplication } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { AzureApplicationOwners } from '../AzureApplicationOwners'
import { AzureApplicationDeputies } from '../AzureApplicationDeputies'
import useSubcomponents from 'useSubcomponents'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: '#307fc1',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
    }),
)

const allSections = [
    {
        name: 'applicationDetails',
        requireAccess: 'ResourceAdmin-ApplicationDetails-GeneralDetails-Column',
    },
    {
        name: 'azureApplicationDetails',
        requireAccess:
            'ResourceAdmin-AzureApplicationDetails-GeneralDetails-Column',
    },
]

export const Overview = (props) => {
    const classes = useStyles()

    const { applicationId } = props

    const { data, isLoading } = useApplication(applicationId)

    const { t } = useTranslation()

    const { trimControlsBasedOnAccess } = useSubcomponents()
    const sections = trimControlsBasedOnAccess(allSections).map((f) => f.name)

    const azureApplicationDetails = data && (
        <Box>
            <Attribute>
                <AttributeLabel
                    className={classes.azureApplicationDetailsLabel}
                >
                    {t('AzureApplicationDetails')}
                </AttributeLabel>
            </Attribute>
            <Box display="flex" flexWrap="wrap">
                <Box paddingRight="32px">
                    {data.azureApplicationFriendlyName && (
                        <Attribute>
                            <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationFriendlyName}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.resourceSystemFriendlyName && (
                        <Attribute>
                            <AttributeLabel>
                                {t('ResourceSystem')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.resourceSystemFriendlyName}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationDescription && (
                        <Attribute>
                            <AttributeLabel>{t('Description')}</AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationDescription}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureIntegrationType && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Type_Of_Integration')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureIntegrationType}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationLine && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Application_Line')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureApplicationLine}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureUpxId && (
                        <Attribute>
                            <AttributeLabel>{t('Upx_ID')}</AttributeLabel>
                            <AttributeValue
                                className={
                                    classes.azureApplicationDetailsIdValues
                                }
                            >
                                {data.azureUpxId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureAuthenticationLevel && (
                        <Attribute>
                            <AttributeLabel>
                                {t('Authentication_Level')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.azureAuthenticationLevel}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.applicationId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('AzureApplicationAppId')}
                            </AttributeLabel>
                            <AttributeValue
                                className={
                                    classes.azureApplicationDetailsIdValues
                                }
                            >
                                {data.applicationId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.azureApplicationId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('AzureApplicationObjectId')}
                            </AttributeLabel>
                            <AttributeValue
                                className={
                                    classes.azureApplicationDetailsIdValues
                                }
                            >
                                {data.azureApplicationId}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.servicePrincipleId && (
                        <Attribute>
                            <AttributeLabel>
                                {t('ServicePrincipleId')}
                            </AttributeLabel>
                            <AttributeValue
                                className={
                                    classes.azureApplicationDetailsIdValues
                                }
                            >
                                {data.servicePrincipleId}
                            </AttributeValue>
                        </Attribute>
                    )}
                </Box>
            </Box>
        </Box>
    )

    const applicationDetails = (
        <Fragment>
            <Box className={classes.basicInfo}>
                <Attribute>
                    <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                    <AttributeValue>{data && data.friendlyName}</AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('TechnicalName')}</AttributeLabel>
                    <AttributeValue>{data && data.name}</AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('Description')}</AttributeLabel>
                    <AttributeValue>{data && data.description}</AttributeValue>
                </Attribute>
            </Box>
        </Fragment>
    )

    const showApplicationDetails = sections.includes('applicationDetails')
    const showAzureApplicationDetails =
        sections.includes('azureApplicationDetails') &&
        data &&
        data.isAzureApplication

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                {showApplicationDetails && (
                    <Box className={classes.section}>{applicationDetails}</Box>
                )}
                {data && !data.isAzureApplication && (
                    <Box className={classes.section}>
                        <Attribute>
                            <AttributeLabel> {t('Owners')}</AttributeLabel>
                            <Owners applicationId={applicationId} />
                        </Attribute>
                    </Box>
                )}
                {showAzureApplicationDetails && (
                    <Box className={classes.section}>
                        {azureApplicationDetails}
                    </Box>
                )}
                {data && data.isAzureApplication && (
                    <Box className={classes.section}>
                        <Attribute>
                            <AttributeLabel>{t('Owners')}</AttributeLabel>
                            <AzureApplicationOwners data={data} />
                        </Attribute>
                    </Box>
                )}
                {data && data.isAzureApplication && (
                    <Box className={classes.section}>
                        <Attribute>
                            <AttributeLabel>{t('Deputies')}</AttributeLabel>
                            <AzureApplicationDeputies data={data} />
                        </Attribute>
                    </Box>
                )}
            </Box>
        </Fragment>
    )

    return (
        <>
            {isLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.section}>{renderContent()}</Box>
                </Fragment>
            )}
        </>
    )
}

import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components'
import attributes from '../containers/Applications/attributes'
import { Dropdown} from 'packages/eid-ui'
import MobileSortingItem from './MobileSortingItem'
import { usePageContext } from 'pageContext'
import { Backdrop, styled } from '@material-ui/core'


const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
        zIndex:'0',
}))


const SortingList = () => {
    const sortableAttributes = attributes.filter(x => x.sortable)

    const [{ sortBy, sortOrder}, dispatch] = usePageContext()

    const handleSort = (heading) => {
        const label = heading.label
        const sortByName = heading.sortBy ? heading.sortBy : heading.name
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: {
                sortBy: sortByName,
                sortLabel: label,
                sortOrder:
                    sortBy !== sortByName
                        ? 'desc'
                        : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                        ? 'desc'
                        : 'asc',
            },
        })
    }

    return (
        <>
                {sortableAttributes.map((attribute, index) => (
                    <MobileSortingItem
                        key={`${attribute.name}_${index}`}
                        displayName={attribute.label}
                        onClick={() =>
                            handleSort(attribute)
                        }
                        selected={sortBy === attribute.sortBy || attribute.friendlyName}
                        order = {sortOrder}
                    ></MobileSortingItem>
                ))}
            
        </>
    )
}

const SortingDropdown = React.forwardRef(
    ({ open, setOpen}, ref) => {

        const handleClose = (event) => {
            if (ref.current && ref.current.contains(event.target)) {
                return
            }

            setOpen(false)
        }
    return(
        <Dropdown
                open={open}
                rootStyles={{
                    paddingRight: '0px',
                }}
                width="320px"
                listStyles={{
                    paddingBottom: '0px',
                    '& > ul': {
                        paddingBottom: '7px',
                        paddingTop: '0px',
                    },
                }}
                anchorEl={ref.current}
                anchorOriginPosition={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorTransformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            
                handleClose={handleClose}
                showCone={false}
                menuHeight="45vh"
            >
               <SortingList/>
            </Dropdown>
    )
}
)


const MobileSortingMenu = ({}) => {
    const [{sortLabel, sortOrder}, dispatch] = usePageContext()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)
    const displayButton = (<>
        <Button.Sort
        label={t('OrderBy')}
        subLabel={sortLabel}
        order = {sortOrder}
        onClick={() => {setOpen((prev) => !prev)}}
    />
        <span ref={anchorRef} style={{marginLeft:'-15px', height:'50px'}}></span> 
    </>
    )

    return (<>
    <StyledBackdrop sx={{ color: '#fff' }} open={open} onClick={setOpen} /> 
        {displayButton}
        {open &&  <><SortingDropdown open={open} setOpen={setOpen} ref={anchorRef}>
                </SortingDropdown></>}
    </>)
}




export default MobileSortingMenu
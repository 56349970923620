import React, { useReducer, createContext, useContext } from 'react'
import reducer, { initialState } from './reducer'

export const CertificatesContext = createContext({})

export const CertificatesContextProvider = ({ children }) => (
    <CertificatesContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </CertificatesContext.Provider>
)

export const useCertificatesContext = () => useContext(CertificatesContext)

import {
    Box,
    styled,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Drawer, Loader } from 'packages/eid-ui'
import { useIsSmallScreen } from 'packages/core'
import StickyContainer from 'components/StickyContainer'
import { useApplicationSettings, useApplicationSecret } from 'hooks'
import { TextFormatter, Button } from 'components'
import bgHeader from '../../../assets/images/bgHeader.png'
import SecretDetails from './SecretDetails'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 32px',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '22px',
    textAlign: 'center',
})

const SecretsDrawer = ({ id, onClose }) => {
    const { data: secret } = useApplicationSecret(id)
    const { data: appConfig } = useApplicationSettings()
    const {t} = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    if (isSmallScreen) {
        return (
            <Box>
                <StickyContainer
                    color="#ffffff"
                    styles={{
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box paddingY="10px" width="100%" maxWidth="260px">
                            <Button.BackButton onClick={() => onClose()}>
                                {t('BackToSecrets')}
                            </Button.BackButton>
                        </Box>

                        <HeaderContainer
                            height="80px"
                            textAlign="start"
                            bgcolor={appConfig.style.colors.primary?.header}
                            style={{
                                backgroundImage: `url(${bgHeader})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <TextFormatter
                                value={
                                    secret?.friendlyName ?? secret?.description
                                }
                                maxCharacters={75}
                            />
                        </HeaderContainer>
                    </Box>
                </StickyContainer>
                {!secret && <Loader />}
                {secret && <SecretDetails secret={secret} />}
            </Box>
        )
    } else {
        return (
            <Drawer
                width="768px"
                bgColor='#fbfbfd'
                open={true}
                toggleDrawer={() => onClose()}
                stickyHeader
                header={
                    <HeaderContainer
                        height="95px"
                        bgcolor={appConfig.style.colors.primary?.header}
                        style={{
                            backgroundImage: `url(${bgHeader})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <TextFormatter
                            value={secret?.friendlyName ?? secret?.description}
                            maxCharacters={385}
                            style={{ textAlign: 'start', fontSize: '22px' }}
                        />
                    </HeaderContainer>
                }
                closeOnOutsideClick
            >
                {!secret && <Loader />}
                {secret && <SecretDetails secret={secret} />}
            </Drawer>
        )
    }
}

export default SecretsDrawer

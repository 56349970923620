import CheckInButton from './CheckIn'
import CheckOutButton from './CheckOut'
import { StatusIndicator } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const CredentialStatusButton = ({ data }) => {
    const { item } = data
    const { t } = useTranslation()

    const checkedOutBySomeoneElse = item.isCheckedOutBySomeoneElse

    if (item.isExpired) {
        return <StatusIndicator
            className="Status-Indicator"
            variant='Expired'
            label={t('Expired')}
            size='medium'
        />
    }

    if (item.canCheckIn) {
        return <CheckInButton data={data} drawerView={false} />
    }
    else if (item.canCheckOut || checkedOutBySomeoneElse) {
        return <CheckOutButton data={data} drawerView={false} checkedOutBySomeoneElse={checkedOutBySomeoneElse} />
    }

    else return <></>
}

export default CredentialStatusButton

import React from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
    Checkbox,
    TableSortLabel,
    Box,
} from '@material-ui/core'
import clsx from 'clsx'
import { Loader, Tooltip } from 'packages/eid-ui'
import { Trans, useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        maxWidth: '220px',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
})

const useSortLabelStyles = makeStyles({
    root: {
        color: '#7d7c7c !important',
        '& svg': {
            color: `#D2D2D9 !important`,
            height: '14px',
            width: '14px',
        },
        '&:hover': {
            color: `#307fc1 !important`,

            '& svg': {
                color: `#307fc1 !important`,
            },
        },
    },

    active: {
        '& svg': {
            color: `#307fc1 !important`,
        },
    },
    icon: {
        opacity: `${1} !important`,
    },
})

const TableGrid = (props) => {
    const {
        data,
        attributes,
        loading,
        rowSelection,
        fallbackMessage,
        onSelect,
        onSort,
        selected,
        tableClasses,
        sorting,
        primaryKey,
    } = props

    const { t } = useTranslation()
    const defaultClasses = useStyles({})
    const classes = tableClasses ?? defaultClasses
    const sortLabelClasses = useSortLabelStyles()

    const handleSort = (attribute) => {
        const sortByName = attribute.sortBy ? attribute.sortBy : attribute.name
        const sortOrder =
            sorting.sortBy !== sortByName
                ? 'desc'
                : !sorting.sortOrder ||
                  sorting.sortOrder === '' ||
                  sorting.sortOrder === 'asc'
                ? 'desc'
                : 'asc'

        onSort({ sortOrder, sortBy: sortByName })
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) =>
                primaryKey ? n[primaryKey] : n.id,
            )
            onSelect(newSelecteds)
            return
        }
        onSelect([])
    }

    const handleClick = (item) => {
        const selectedIndex = selected.indexOf(
            primaryKey ? item[primaryKey] : item.id,
        )
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(
                selected,
                primaryKey ? item[primaryKey] : item.id,
            )
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        onSelect(newSelected)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    const getValue = (attribute, item) => {
        if (attribute.resolve) {
            if (!attribute.showText) {
                return attribute.resolve(item, attribute)
            } else {
                return (
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box style={{ paddingRight: 0 }}>
                            {item[attribute.name] ?? '-'}
                        </Box>
                        <Box
                            style={{
                                position: 'relative',
                            }}
                        >
                            {attribute.resolve(item, attribute)}
                        </Box>
                    </Box>
                )
            }
        }

        return item[attribute.name] ?? '-'
    }

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow className={classes.tableRow}>
                    {rowSelection && (
                        <TableCell
                            padding="checkbox"
                            className={classes.headGrey}
                        >
                            <Checkbox
                                icon={<Icon name="CheckBox" />}
                                checkedIcon={<Icon name="CheckedBox" />}
                                checked={
                                    data?.length > 0 &&
                                    selected.length === data?.length
                                }
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                    )}

                    {attributes.map((heading, index) => {
                        const sortByName = heading.sortBy
                            ? heading.sortBy
                            : heading.name
                        return (
                            <TableCell
                                key={index}
                                className={clsx(
                                    classes.tableHeaderCell,
                                    classes.tableCell,
                                    classes.headGrey,
                                )}
                                align={heading.align}
                            >
                                {!heading.sortable ? (
                                    heading.label
                                ) : (
                                    <span style={{ display: 'flex' }}>
                                        <Tooltip
                                            title={
                                                sorting.sortBy === sortByName &&
                                                sorting.sortOrder === 'desc'
                                                    ? t('SortAscending')
                                                    : t('SortDescending')
                                            }
                                        >
                                            <TableSortLabel
                                                classes={sortLabelClasses}
                                                active={
                                                    sorting.sortBy ===
                                                    sortByName
                                                }
                                                direction={
                                                    sorting.sortBy ===
                                                    sortByName
                                                        ? sorting.sortOrder
                                                        : 'asc'
                                                }
                                                onClick={() =>
                                                    handleSort(heading)
                                                }
                                            >
                                                {t(heading.label)}
                                            </TableSortLabel>
                                        </Tooltip>
                                    </span>
                                )}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {loading && (
                    <TableRow>
                        <TableCell
                            colSpan={attributes.length}
                            style={{ textAlign: 'center' }}
                        >
                            <Loader />
                        </TableCell>
                    </TableRow>
                )}
                {data &&
                    (data.length === 0 ? (
                        <TableRow className={classes.tableRow}>
                            <TableCell
                                className={classes.tableCell}
                                colSpan={attributes.length}
                                style={{ textAlign: 'center' }}
                            >
                                {fallbackMessage}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {data.map((item) => (
                                <TableRow
                                    key={
                                        primaryKey ? item[primaryKey] : item.id
                                    }
                                    className={classes.tableRow}
                                >
                                    {rowSelection && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                icon={<Icon name="CheckBox" />}
                                                checkedIcon={
                                                    <Icon name="CheckedBox" />
                                                }
                                                checked={isSelected(
                                                    primaryKey
                                                        ? item[primaryKey]
                                                        : item.id,
                                                )}
                                                onClick={() =>
                                                    handleClick(item)
                                                }
                                            />
                                        </TableCell>
                                    )}

                                    {attributes.map((a, index) => (
                                        <TableCell
                                            key={`${
                                                primaryKey
                                                    ? item[primaryKey]
                                                    : item.id
                                            }${index}`}
                                            className={classes.tableCell}
                                            style={a.style}
                                        >
                                            {getValue(a, item)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </>
                    ))}
            </TableBody>
        </Table>
    )
}

TableGrid.defaultProps = {
    fallbackMessage: <Trans i18nKey="NoDataFound" />,
}
export default TableGrid

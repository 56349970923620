import { useTranslation } from 'react-i18next'

const CalculateTimeString = ({ data, attribute }) => {
    const { t } = useTranslation()
    const { item } = data
    const getTimeInString = (value) => {
        const splitted = value.split(':')
        let days,
            hours,
            minutes = 0
        if (splitted && splitted[0].length) {
            days = +splitted[0]
        }
        if (splitted && splitted[1].length) {
            hours = +splitted[1]
        }
        if (splitted && splitted[2].length) {
            minutes = +splitted[2]
        }
        return `${days} days ${hours} hours ${minutes} mins`
    }
    return (
        <>
            {item && item[attribute.name] === null
                ? '-'
                : getTimeInString(item[attribute.name])}
        </>
    )
}
export default CalculateTimeString

import { Fragment } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { Owners } from '../../../../components/Owners/Owners'
import { useAccessRequestPolicy, useManagementRole } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import DynamicComponents from 'components/DynamicComponents'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
    }),
)

export const Overview = (props) => {
    const classes = useStyles()

    const { basicAttributes, managementRoleId } = props
    const { isLoading: detailsLoading, data: managementRole } =
        useManagementRole(managementRoleId)
    const policy = useAccessRequestPolicy(
        managementRole && managementRole.requestPolicyId,
    )

    const { t } = useTranslation()

    const renderComponent = (a, item) => {
        const DynamicComponentToRender =
            DynamicComponents[a.component.componentName]
        return <DynamicComponentToRender attribute={a} data={{ item }} />
    }

    const managementRoleDetails = (
        <Fragment>
            <Box className={classes.basicInfo}>
                {basicAttributes &&
                    basicAttributes.map((att, i) => {
                        return (
                            <Attribute key={att.value + i}>
                                <AttributeLabel>{t(att.label)}</AttributeLabel>
                                <AttributeValue>
                                    {att.component &&
                                        renderComponent(att, managementRole)}
                                    {managementRole &&
                                        !att.hideValue &&
                                        managementRole[att.value]}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
            </Box>
        </Fragment>
    )
    const policyDetails = (
        <Box>
            <Attribute>
                <AttributeLabel>{t('AccessRequestPolicy')}</AttributeLabel>
                <AttributeValue>{policy && policy.name}</AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>
                    {t('AllowsTimeConstrainedAccess')}
                </AttributeLabel>
                <AttributeValue>
                    {policy && policy.isTimeConstrained ? t('Yes') : t('No')}
                </AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>{t('DefaultAccessDuration')}</AttributeLabel>
                <AttributeValue>
                    {policy && policy.defaultValueInMinutes}
                </AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>{t('MaxAccessDuration')}</AttributeLabel>
                <AttributeValue>
                    {policy && policy.maximumValueInMinutes}
                </AttributeValue>
            </Attribute>
        </Box>
    )
    const showApplicationDetails = true
    const showPolicyDetails = true
    // TODO: Create sub task to protect all sub components

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                {showApplicationDetails && (
                    <Box className={classes.section}>
                        {managementRoleDetails}
                    </Box>
                )}
                {showPolicyDetails && (
                    <Box className={classes.section}>{policyDetails}</Box>
                )}
                <Box className={classes.section}>
                    <Attribute>
                        <AttributeLabel> {t('Owners')}</AttributeLabel>
                        <Owners resourceId={managementRole.id} />
                    </Attribute>
                </Box>
                {/* TODO: Need to implement approvers */}
                <Box className={classes.section}>
                    <Attribute>
                        <AttributeLabel> {t('Approvers')}</AttributeLabel>
                        {/* TODO: Change below when approvers call is done from backend */}
                        <Owners resourceId={managementRole.id} />
                    </Attribute>
                </Box>
            </Box>
        </Fragment>
    )

    return (
        <>
            {detailsLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.section}>{renderContent()}</Box>
                </Fragment>
            )}
        </>
    )
}

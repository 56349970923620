import { useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useApplicationSettings, useApplication } from '../../hooks'

import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import StickyContainer from 'components/StickyContainer'
import { ItemDetails, Button, TextFormatter } from 'components'
import { Actions } from './../../components/ApplicationBanner/Actions'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import ApplicationCertificates from 'components/ApplicationBanner/Application Certificates/ApplicationCertificates'
import ApplicationSecrets from 'components/ApplicationBanner/Application Secrets/ApplicationSecrets'
import SecretsDrawer from 'components/ApplicationBanner/Application Secrets/SecretsDrawer'
import CertificatesDrawer from 'components/ApplicationBanner/Application Certificates/CertificatesDrawer'
import bgHeader from '../../assets/images/bgHeader.png'
import { SecretsContextProvider } from 'secretsContext'
import { CertificatesContextProvider } from 'certificatesContext'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 32px',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '22px',
    textAlign: 'center',
})


const ApplicationDetails = ({ id, onClose }) => {
    const { data: application } = useApplication(id)
    const { data: appConfig } = useApplicationSettings()
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const secretId = query.get('secretId')
    const certificateId = query.get('certificateId')
    const [activeTab, setActiveTab] = useState('overview')

    if (secretId) {
        return (
            <SecretsContextProvider key="RequestViewSecrets">
                <SecretsDrawer
                    id={secretId}
                    onClose={() => {
                        query.delete('secretId')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            </SecretsContextProvider>
        )
    }

    if (certificateId) {
        return (
            <CertificatesContextProvider key="RequestViewCertificates">
                <CertificatesDrawer
                    id={certificateId}
                    onClose={() => {
                        query.delete('certificateId')
                        history.push(
                            `${history.location.pathname}?${query.toString()}`,
                        )
                    }}
                />
            </CertificatesContextProvider>
        )
    }

    return (
        <Box>
            <StickyContainer
                color="#ffffff"
                styles={{
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Box paddingY="10px" width="100%" maxWidth="260px">
                        <Button.BackButton onClick={() => onClose()}>
                            {t('BackToApplications')}
                        </Button.BackButton>
                    </Box>

                    <HeaderContainer
                        height="80px"
                        textAlign="start"
                        bgcolor={appConfig.style.colors.primary?.header}
                        style={{
                            backgroundImage: `url(${bgHeader})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    >
                        <TextFormatter
                            value={
                                application?.description ??
                                application?.name
                            }
                            maxCharacters={75}
                        />
                    </HeaderContainer>
                </Box>
            </StickyContainer>
            <ItemDetails.Tabs
                variant="scrollable"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab value="overview" label={t('Overview')} />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('MoreInformation')}
                />
                {application?.isAzureApplication && (
                    <ItemDetails.Tabs.Tab
                        label={t('ClientSecrets')}
                        value="secrets"
                    />
                )}
                {application?.isAzureApplication && (
                    <ItemDetails.Tabs.Tab
                        label={t('ClientCertificates')}
                        value="certificates"
                    />
                )}
                <ItemDetails.Tabs.Tab value="action" label={t('Actions')} />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection applicationId={application?.id} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection application={application} />
            )}
            {activeTab === 'secrets' && (
                <SecretsContextProvider key="RequestViewSecrets">
                    <ApplicationSecrets
                        azureApplicationId={application?.azureApplicationId}
                    />
                </SecretsContextProvider>
            )}
            {activeTab === 'certificates' && (
                <CertificatesContextProvider key="RequestViewCertificates">
                    <ApplicationCertificates
                        azureApplicationId={application?.azureApplicationId}
                    />
                </CertificatesContextProvider>
            )}
            {activeTab === 'action' && (
                <Box
                    display="flex"
                    width="100%"
                    height={500}
                    bgcolor="#ffffff"
                    justifyContent="center"
                >
                    <Box paddingTop="30px">
                        <Actions application={application} />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default ApplicationDetails

import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { EidChecklist } from 'packages/eid-controls'
import { isNilOrEmpty } from 'packages/core'

import { useTranslation } from 'react-i18next'

const ChecklistFilter = (props) => {
    const {
        value,
        onChange,
        defaultValue,
        selectedValueIndicatorProp,
        url,
        queryParams,
        basedOn,
        searchPlaceholder,
        state,
        ...rest
    } = props
    const { t } = useTranslation()

    const [selectedItems, setSelectedItems] = useState(value)

    useEffect(() => {
        if (value !== selectedItems) {
            setSelectedItems(value)
        }
    }, [value])

    useEffect(() => {
        if (value !== selectedItems) {
            onChange(selectedItems)
        }
    }, [selectedItems])

    let urlToUse
    let queryParamsToUse = {
        ...queryParams,
    }
    if (!isNilOrEmpty(basedOn)) {
        const basedOnValue = state[basedOn.contextProp]

        switch (basedOn.type) {
            case 'required':
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                }
                break
            case 'optional':
            default:
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                } else {
                    urlToUse = url
                }
                break
        }
    } else {
        urlToUse = url
    }

    return (
        <Box paddingLeft="10px" paddingTop="15px">
            <EidChecklist
                url={urlToUse}
                shouldTriggerApiCall={!isNilOrEmpty(urlToUse)}
                queryParams={queryParamsToUse}
                onChange={(item) => {
                    const currentIndex = selectedItems.findIndex(
                        (i) => i.id === item.id,
                    )
                    const newChecked = [...selectedItems]

                    if (currentIndex === -1) {
                        newChecked.push(item)
                    } else {
                        newChecked.splice(currentIndex, 1)
                    }

                    setSelectedItems(newChecked)
                }}
                value={selectedItems}
                fallbackMessage={t('NoDataFound')}
                searchPlaceholder={searchPlaceholder}
                {...rest}
            />
        </Box>
    )
}

export default ChecklistFilter

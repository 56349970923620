import { useState } from 'react'
import { useAppState } from 'appContext'
import { Icon } from 'packages/eid-icons'
import { Button } from 'components'
import { PasswordModal } from '../../components/PasswordModal'
import { useTranslation } from 'react-i18next'

const MasterPasswordLock = ({ style }) => {
    const { t } = useTranslation()

    const [{ masterPassword }] = useAppState()
    const [masterPasswordOpen, setMasterPasswordOpen] = useState('')

    if (masterPassword) return <></>

    return (
        <PasswordModal
            open={masterPasswordOpen}
            setOpen={setMasterPasswordOpen}
        >
            <div
                style={{
                    position: 'absolute',
                    display: 'flex',
                    cursor: 'pointer',
                    background: '#f2f6fbbf',
                    zIndex: 1,
                    ...style,
                }}
                onClick={() =>
                    setMasterPasswordOpen(
                        masterPassword ? 'addToCart' : 'enterPassword',
                    )
                }
            >
                <Button
                    style={{
                        height: 20,
                        background: '#262fa1',
                        color: 'white',
                        fontSize: 12,
                        padding: 8,
                        margin: 'auto',
                        borderRadius: '10px',
                        '&:hover': {
                            background: '#262fa1',
                        },
                    }}
                    startIcon={
                        <Icon
                            style={{
                                marginRight: '-4px',
                            }}
                            name={'LockRound'}
                        ></Icon>
                    }
                    onClick={() =>
                        setMasterPasswordOpen(
                            masterPassword ? 'addToCart' : 'enterPassword',
                        )
                    }
                >
                    {t('UNLOCK')}
                </Button>
            </div>
        </PasswordModal>
    )
}

export default MasterPasswordLock

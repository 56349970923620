import React, { useState, Fragment } from 'react'
import { usePerson } from 'hooks/personHooks'
import { PersonDetails } from 'components/ApplicationBanner/PersonDetails'
import { useTranslation } from 'react-i18next'

const ApplicationOwner = ({ data }) => {
    const { item } = data

    const [enabled, setEnabled] = useState(false)
    const { data: person, isLoading } = usePerson(item.ownerId, enabled)
    const { t } = useTranslation()

    if (item && item.ownerIsPerson) {
        return (
            <div
                onMouseEnter={() => {
                    setEnabled(true)
                }}
            >
                <PersonDetails
                    size="medium"
                    enabled={enabled}
                    loading={isLoading}
                    person={{
                        imageThumbUrl: item.ownerImageThumbUrl,
                        friendlyName: item.ownerFriendlyName,
                        email: person && person.email,
                        telephone: person && person.telephone,
                    }}
                />
            </div>
        )
    }

    return (
        <Fragment>
            {item.ownerFriendlyName ? item.ownerFriendlyName : '-'}
        </Fragment>
    )
}
export default ApplicationOwner

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled, createStyles, makeStyles } from '@material-ui/core'
import { Loader, ToggleSwitch } from 'packages/eid-ui'
import { ItemDetails, DeleteCredential, EditCredential } from 'components'
import { OverviewSection } from './OverviewSection'
import DeleteScope from './DeleteScope'
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '16px',
            [theme.breakpoints.up('sm')]: {
                height: '80px',
                alignItems: 'center',
                position: 'relative',
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                paddingTop: '16px !important',
                paddingBottom: '16px !important',
            },
        },
    }),
)

const PaddedDiv = styled(Box)({
    padding: '0',
})

const ScopeDetails = ({ scope }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState('overview')
    const ScopeState = ({ item }) => {
        const [status, setStatues] = useState(item.state)

        return (
            <ToggleSwitch
                onChange={() => {
                    setStatues((pre) => !pre)
                }}
                value={status}
                labelColor={status ? 'black' : '#848992'}
                label={status ? 'Enabled' : 'Disabled'}
                style={{ marginRight: '20px' }}
                fontSize={'16px'}
            />
        )
    }

    return (
        <>
            {!scope ? (
                <Loader />
            ) : (
                <div style={{ height: '100%' }}>
                    <ItemDetails.Tabs
                        variant="standard"
                        value={activeTab}
                        onChange={(_, value) => setActiveTab(value)}
                    >
                        <ItemDetails.Tabs.Tab
                            value="overview"
                            label={t('Overview')}
                        />
                    </ItemDetails.Tabs>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '90%',
                        justifyContent: 'space-between',

                    }}>
                        <Box>
                            {activeTab === 'overview' && (
                                <OverviewSection scope={scope} />
                            )}
                        </Box>
                        <Box>
                            <PaddedDiv className={classes.root}>
                                {/* TODO: Data is not present in DB
                                <ScopeState item={scope}></ScopeState>
                            <EditCredential item={scope} /> */}
                                <DeleteScope item={scope} drawerView={true} />
                            </PaddedDiv>
                        </Box>
                    </Box>
                </div>
            )}
        </>
    )
}

export default ScopeDetails

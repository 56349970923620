import { usePerson } from 'hooks/personHooks'
import { PersonDetails } from 'components/ApplicationBanner/PersonDetails'

const PersonComponent = ({ attribute, data }) => {
    const {item} = data
    const { data: person, } = usePerson(item[attribute.name], true)
    return (
        <div>
            <PersonDetails
                size="medium"
                person={{
                    imageThumbUrl: person && person.imageThumbUrl,
                    friendlyName: person && person.friendlyName,
                    email: person && person.email,
                    telephone: person && person.telephone,
                }}
            />
        </div>
    )
}
export default PersonComponent

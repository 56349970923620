import React from 'react'
import { isNilOrEmpty } from 'packages/core'
import { EidAutocomplete } from 'packages/eid-controls'
import { Icon } from 'packages/eid-icons'

const AutocompleteFilter = ({
    value,
    onChange,
    optionConfig,
    selectedValueIndicatorProp,
    requireAccess,
    queryParam,
    queryParams,
    basedOn,
    url,
    supportsServerSideSearch = true,
    state,
    appState,
    excludeOptionsConfig,
    clearable = true,
    ...rest
}) => {
    let urlToUse
    let queryParamsToUse = {
        ...queryParams,
    }
    if (!isNilOrEmpty(basedOn)) {
        const basedOnValue = state[basedOn.contextProp]

        switch (basedOn.type) {
            case 'required':
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                }
                break
            case 'optional':
            default:
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                } else {
                    urlToUse = url
                }
                break
        }
    } else {
        urlToUse = url
    }

    const excludeOptions = []
    if (excludeOptionsConfig) {
        const stateToUse =
            excludeOptionsConfig.contextType === 'global' ? appState : state
        var optionToExcludeValue = stateToUse[excludeOptionsConfig.contextProp]
        if (optionToExcludeValue) {
            excludeOptions.push(
                optionToExcludeValue[excludeOptionsConfig.valueProp],
            )
        }
    }

    const clearIcon = (
        <Icon name="Close" color="#959598" onClick={() => onChange(null)} />
    )

    return (
        <EidAutocomplete
            getOptionLabel={(option) => option[optionConfig.labelProp]}
            optionComponent={({ option, optionState }) => {
                const OptionComponent = EidAutocomplete.getOptionComponent(
                    optionConfig,
                )
                return (
                    <OptionComponent
                        option={option}
                        optionState={optionState}
                    />
                )
            }}
            value={value}
            onChange={(_, value) => {
                const valueToDispatch = value ? value : null
                onChange(valueToDispatch)
            }}
            shouldTriggerApiCall={!isNilOrEmpty(urlToUse)}
            url={urlToUse}
            queryParams={queryParamsToUse}
            filterOptions={
                supportsServerSideSearch ? (options) => options : undefined
            }
            supportsServerSideSearch={supportsServerSideSearch}
            excludeOptions={excludeOptions}
            clearIcon={clearable && !isNilOrEmpty(value) && clearIcon}
            {...rest}
        />
    )
}

export default AutocompleteFilter

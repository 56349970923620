import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'
import { useApplicationPermissions } from 'hooks'
import { useEidGrid, CardViewLoader } from 'components'
import {
    PermissionRow,
    PermissionsTable,
    PermissionsTableHead,
} from './PermissionsTableComponent'
import permissionsAttributes from './permissionsAttributes'
import PermissionCard from './PermissionsCard'

const ApplicationPermissions = ({ id }) => {
    const { t } = useTranslation()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationPermissions(id)

    const isSmallScreen = useIsSmallScreen()

    const defaultView = isSmallScreen ? 'card' : 'table'
    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoPermissionsMessage'),
        pagination,
        attributes: permissionsAttributes,
        // cardViewConfig: { TODO: Enable after Mobile UX is done
        //     LoadingIndicatorComponent: CardViewLoader,
        //     CardComponent: PermissionCard,
        // },
        tableViewConfig: {
            TableComponent: PermissionsTable,
            TableHeadComponent: PermissionsTableHead,
            TableRowComponent: PermissionRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    return <>{viewToRender}</>
}

export default ApplicationPermissions

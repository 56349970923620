const certificatesAttributes = [
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        label: 'FriendlyName',

        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
        },
        styles: {
            width: '150px',
            maxWidth: '150px',
            minWidth: '150px',
            color: '#282828',
            fontSize: '13px',
            fontWeight: 'bold',
        },
    },
    {
        name: 'ownerFriendlyName',
        sortable: true,
        hideValue: true,
        sortBy: 'ownerFriendlyName',
        label: 'Owner',
        component: {
            componentName: 'ApplicationOwner',
        },
        headerCellStyles: {
            width: '160px',
            minWidth: '160px',
            maxWidth: '160px',
            fontSize: '13px',
        },
        styles: {
            minWidth: '160px',
            maxWidth: '160px',
            width: '160px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'password',
        sortable: false,
        sortBy: 'password',
        hideValue: true,
        label: 'PublicKey',
        showMasterLock: true, 
        component: {
            componentName: 'CredentialLockedBox',
        },

        headerCellStyles: {
            width: '100px',
            minWidth: '100px',
            maxWidth: '100px',
        },
        styles: {
            width: '100px',
            minWidth: '100px',
            maxWidth: '100px',
            color: '#282828',
            fontSize: '13px',
            padding: '0px'
        },
    },
    {
        name: 'status',
        hideValue: true,
        label: 'Status',
        showMasterLock: true, 
        component: {
            componentName: 'CredentialStatusButton',
        },
        headerCellStyles: {
            width: '130px',
            minWidth: '130px',
            maxWidth: '130px',
            fontSize: '13px',
        },
        styles: {
            width: '110px',
            minWidth: '110px',
            maxWidth: '120px',
        },
    },
    {
        name: 'endDateTimeUtc',
        sortable: true,
        sortBy: 'endDateTimeUtc',
        label: 'RemainingTime',
        hideValue: true,
        component: {
            componentName: 'CredentialRemainingTime',
        },
        headerCellStyles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            fontSize: '13px',
        },
        styles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        hideValue: true,
        component: {
            componentName: 'actionButtons',
        },
        styles: {
            width: '250px',
            minWidth: '250px',
            maxWidth: '250px',
        },

        headerCellStyles: {
            width: '250px',
            minWidth: '250px',
            maxWidth: '250px',
        },
    },
]

export default certificatesAttributes

import { Box, styled, Grid, Paper, FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Loader, Avatar, WithCloseButton } from 'packages/eid-ui'
import { isNilOrEmpty } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useCertificatesContext } from 'certificatesContext'
import config from 'config'
import { useCheckOutCredentialsRecords } from 'hooks'
import PeopleSearch from 'components/Filters/PeopleSearch'
import CheckoutHistoryCard from 'components/CheckoutHistoryCard'
import DateTimePicker from 'components/DateTimePicker'
import checkoutHistoryCardAttributes from './checkoutHistoryCardAttributes'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            paddingTop: '25px',
            padding: '20px 24px',
        },
        formControlLabel: {
            marginLeft: '24px',
        }
    }),
)

const SelectedPersonIndicator = styled(WithCloseButton)({
    borderRadius: '5px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
})

const PersonLabel = styled('label')({
    padding: '0 0 10px 8px',
    lineHeight: '11px',
    fontSize: '10px',
    display: 'block',
    color: ' #b4b4b4',
    textTransform: 'uppercase',
})


export const CheckoutHistory = ({ certificate }) => {
    const { t } = useTranslation()
    const [state, dispatch] = useCertificatesContext()
    const queryData = {}

    if (state.startDateFilter) {
        queryData.StartDateAfter = state.startDateFilter
    }
    if (state.endDateFilter) {
        queryData.EndDateBefore = state.endDateFilter
    }
    if (state.activeCheckOut) {
        queryData.activeCheckOut = state.activeCheckOut
    }
    if (state.activeCheckOutBy) {
        queryData.targetPersonId = state.activeCheckOutBy.id
    }

    const classes = useStyles()
    const {
        list: history,
        loading: historyLoading,
        pagination,
    } = useCheckOutCredentialsRecords(certificate.id, queryData)

    return (
        <Box style={{ background: '#fff' }}>
            <Box
                style={{
                    padding: '30px 0px 0px 24px',
                    display: 'flex',
                }}
            >
                <DateTimePicker
                    showLabelWithin
                    disableFuture
                    boxStyle={{ style: { maxWidth: '260px' } }}
                    label={t('StartDate')}
                    value={
                        isNilOrEmpty(state['startDateFilter'])
                            ? null
                            : state['startDateFilter'].clone().local()
                    }
                    onChange={(date) => {
                        const dateToSet = date.clone()
                        dateToSet.startOf('day')
                        dateToSet.utc()

                        dispatch({
                            type: 'SET_PROP',
                            payload: {
                                key: 'startDateFilter',
                                value: dateToSet,
                            },
                        })
                    }}
                    handleClear={() =>
                        dispatch({
                            type: 'SET_PROP',
                            payload: {
                                key: 'startDateFilter',
                                value: null,
                            },
                        })
                    }
                />
                <DateTimePicker
                    showLabelWithin
                    boxStyle={{
                        style: { marginLeft: '20px', maxWidth: '260px' },
                    }}
                    disableFuture
                    minDate={
                        state['startDateFilter']
                            ? state['startDateFilter']
                            : null
                    }
                    label={t('EndDate')}
                    value={
                        isNilOrEmpty(state['endDateFilter'])
                            ? null
                            : state['endDateFilter'].clone().local()
                    }
                    onChange={(date) => {
                        const dateToSet = date.clone()
                        dateToSet.startOf('day')
                        dateToSet.utc()

                        dispatch({
                            type: 'SET_PROP',
                            payload: {
                                key: 'endDateFilter',
                                value: dateToSet,
                            },
                        })
                    }}
                    handleClear={() =>
                        dispatch({
                            type: 'SET_PROP',
                            payload: {
                                key: 'endDateFilter',
                                value: null,
                            },
                        })
                    }
                />
                <FormControlLabel
                    className={classes.formControlLabel}
                    control={
                        <Checkbox
                            checked={state.activeCheckOut}
                            icon={<Icon name="CheckBox" />}
                            checkedIcon={<Icon name="CheckedBox" />}
                            onChange={() =>
                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: 'activeCheckOut',
                                        value: !state.activeCheckOut,
                                    },
                                })
                            }
                        />
                    }
                    label={<Typography style={{ fontSize: '14px' }}>{t('CheckedOut')}</Typography>}
                />
            </Box>
            <Box style={{
                padding: '24px 0px 0px 24px',
                width: '290px',
            }}
            >
                {state.activeCheckOutBy ? (
                    <>
                        <PersonLabel>{t('CheckedOutBy')}</PersonLabel>
                        <SelectedPersonIndicator
                            onClose={() => {
                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: 'activeCheckOutBy',
                                        value: null,
                                    },
                                })
                            }}
                        >
                            <Avatar
                                src={`${config.BASE_EID_URL}${state.activeCheckOutBy.imageThumbUrl}`}
                            />
                            <Typography
                                style={{
                                    marginLeft: '21px',
                                    wordBreak: 'break-all',
                                }}
                                variant="body1"
                            >
                                {state.activeCheckOutBy.friendlyName}
                            </Typography>
                        </SelectedPersonIndicator>
                    </>
                ) : (
                    <PeopleSearch
                        value={state.activeCheckOutBy}
                        labelComponent={() => (<PersonLabel>{t('CheckedOutBy')}</PersonLabel>)}
                        onChange={(_, value) =>
                            dispatch({
                                type: 'SET_PROP',
                                payload: {
                                    key: 'activeCheckOutBy',
                                    value: value,
                                },
                            })
                        }
                    />)}
            </Box>

            {historyLoading ? (
                <div style={{ alignSelf: 'center' }}>
                    <Loader />
                </div>
            ) : history.length === 0 ? (
                <Typography style={{ padding: '24px 24px', fontSize: '14px' }}>
                    {t('NoCheckOutHistoryFound')}
                </Typography>
            ) :
                (
                    <Paper className={classes.root}>
                        <Grid
                            container
                            spacing={2}
                            style={{ justifyContent: 'start' }}
                        >
                            {history.map((item, index) => (
                                <CheckoutHistoryCard
                                    key={index}
                                    item={item}
                                    attributes={checkoutHistoryCardAttributes}
                                />
                            ))}
                        </Grid>
                        <Box
                            display="flex"
                            marginTop={'20px'}
                            justifyContent="center"
                        >
                            {pagination}
                        </Box>
                    </Paper>
                )}
        </Box>
    )
}

import { FC, useState, useEffect, useRef, useCallback } from 'react'
import { Box, Dialog, Paper } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Icon } from 'packages/eid-icons'
import {
    Attribute,
    AttributeLabel,
    AttributeValue,
    IconButton,
    Loader,
    Tooltip,
} from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { usePeople } from 'hooks'

const useStyles = makeStyles(() =>
    createStyles({
        rootListView: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            minHeight: '32px',
            top: '-18px',
            right: '0px',
        },
        icon: {
            position: 'absolute',
            top: 0,
            cursor: 'pointer',
        },
        attributeLabel: {
            fontSize: '13px',
        },
        attributeValue: {
            fontSize: '14px',
            fontWeight: '600',
        },
        attributeValueMobile: {
            fontSize: '14px',
            color: '#307fc1',
        },
        attribute: {
            paddingTop: 4,
            paddingBottom: 4,
        },
        modal: {
            backgroundColor: '#f7f8fa',
            borderRadius: '8px',
            padding: 0,
            '&:focus': {
                outline: 'none',
            },
        },
        modalHeader: {
            display: 'flex',
            justifyContent: 'end',
            minHeight: '20px',
            padding: '16px 8px',
        },
        closeIcon: {
            display: 'flex',
            marginRight: '-16px',
            marginTop: '-16px',
            height: '10px',
        },
        content: {
            padding: 20,
            boxShadow:
                '0 4px 16px 0 rgba(0, 0, 0, 0.1), 0 8px 32px 0 rgba(0, 0, 0, 0.1)',
            borderStyle: 'solid',
            borderImageWidth: '0 0px 3px 0px',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 37%, #2c83bd 71%, rgba(48, 127, 193, 0))',
            borderImageSlice: 1,
        },
    }),
)

const PeopleDetails = (props) => {
    const classes = useStyles()

    const { t } = useTranslation()

    const { data } = props
    const ref = useRef(null)
    const [enableApi, setEnableApi] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [hover, setHover] = useState(false)

    const { data: people, isLoading } = usePeople(data['id'], enableApi)
    useEffect(() => {
        if (showModal) {
            setEnableApi(true)
        }
    }, [showModal])

    const renderModal = () => {
        return (
            <Dialog fullWidth scroll={'body'} open={showModal}>
                <Box className={classes.modal}>
                    <Box className={classes.content}>
                        <Box className={classes.modalHeader}>
                            <Box className={classes.closeIcon}>
                                <IconButton>
                                    <Icon
                                        onClick={() => setShowModal(false)}
                                        name="Close"
                                        color="#959598"
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                        {renderAttributes()}
                    </Box>
                </Box>
            </Dialog>
        )
    }
    const renderContent = () => {
        const { x, y } = ref.current.getClientRects()[0]
        const isBelowPage = window.innerHeight - y < 330
        return (
            <Paper
                className={classes.content}
                style={{
                    minWidth: '600px',
                    maxWidth: '800px',
                    position: 'fixed',
                    maxHeight: '330px',
                    zIndex: 1200,
                    left: x + (4 / 100) * x,
                    top: isBelowPage ? 'unset' : y - (24 / 100) * y,
                    bottom: isBelowPage ? window.innerHeight - y : 'unset',
                }}
            >
                {renderAttributes()}
            </Paper>
        )
    }
    const renderAttributes = () => {
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    <Box>
                        <Attribute
                            className={classes.attribute}
                            orientation="horizontal"
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('LastName')}
                            </AttributeLabel>
                            <AttributeValue className={classes.attributeValue}>
                                {people?.lastName}
                            </AttributeValue>
                        </Attribute>
                        <Attribute
                            className={classes.attribute}
                            orientation="horizontal"
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('FirstName')}
                            </AttributeLabel>
                            <AttributeValue className={classes.attributeValue}>
                                {people?.firstName}
                            </AttributeValue>
                        </Attribute>
                        <Attribute
                            className={classes.attribute}
                            orientation="horizontal"
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('Department')}
                            </AttributeLabel>
                            <AttributeValue className={classes.attributeValue}>
                                {people?.department}
                            </AttributeValue>
                        </Attribute>
                        <Attribute
                            className={classes.attribute}
                            orientation="horizontal"
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('Title')}
                            </AttributeLabel>
                            <AttributeValue className={classes.attributeValue}>
                                {people?.title}
                            </AttributeValue>
                        </Attribute>
                        <Attribute
                            className={classes.attribute}
                            orientation="horizontal"
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('City')}
                            </AttributeLabel>
                            <AttributeValue className={classes.attributeValue}>
                                {people?.city}
                            </AttributeValue>
                        </Attribute>
                        <Attribute
                            className={classes.attribute}
                            orientation="horizontal"
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('Country')}
                            </AttributeLabel>
                            <AttributeValue className={classes.attributeValue}>
                                {people?.country}
                            </AttributeValue>
                        </Attribute>
                        <Attribute
                            className={classes.attribute}
                            orientation="horizontal"
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('Telephone')}
                            </AttributeLabel>
                            <AttributeValue
                                className={
                                    people?.telephone
                                        ? classes.attributeValueMobile
                                        : classes.attributeValue
                                }
                            >
                                {people?.telephone}
                            </AttributeValue>
                        </Attribute>
                        <Attribute
                            className={classes.attribute}
                            orientation="horizontal"
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('Email')}
                            </AttributeLabel>
                            <AttributeValue
                                className={
                                    people?.email
                                        ? classes.attributeValueMobile
                                        : classes.attributeValue
                                }
                            >
                                {people?.email}
                            </AttributeValue>
                        </Attribute>
                    </Box>
                )}
            </>
        )
    }
    return (
        <>
            {data && (
                <Box className={classes.rootListView}>
                    <Box>
                        <Tooltip
                            color={'transparent'}
                            arrow={false}
                            title={''}
                            open={!showModal && hover}
                        >
                            <Box
                                className={classes.icon}
                                onClick={() => {
                                    setShowModal(true)
                                }}
                                onMouseEnter={() => {
                                    setEnableApi(true)
                                    setHover(true)
                                }}
                                onMouseLeave={() => {
                                    setEnableApi(false)
                                    setHover(false)
                                }}
                            >
                                <div ref={ref}>
                                    <Icon
                                        name="Info"
                                        color={
                                            showModal || hover
                                                ? '#307fc1'
                                                : '#8b909a'
                                        }
                                        width="24px"
                                        height="25px"
                                    ></Icon>
                                </div>
                            </Box>
                        </Tooltip>
                        {!showModal && hover && <div>{renderContent()}</div>}
                        {showModal && <div>{renderModal()}</div>}
                    </Box>
                </Box>
            )}
        </>
    )
}
export default PeopleDetails

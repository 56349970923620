import { default as ConnectSwitch } from './ConnectSwitch'
import { default as DummyPasswordBox } from './DummyPasswordBox'
import { default as DateTimeComponent } from './DateTimeComponent'
import { default as ApplicationOwner } from './ApplicationOwner'
import { default as ApplicationImage } from './ApplicationImage'
import { default as HighlightedClickableLabel } from './HighlightedClickableLabel'
import { default as CheckInButton } from './CheckIn'
import { default as CheckOutButton } from './CheckOut'
import { default as CredentialStatusButton } from './CredentialStatusButton'
import { default as CredentialLockedBox } from './CredentialLockedBox'
import { default as CredentialRemainingTime } from './CredentialRemainingTime'
import { default as DateTimeGenericComponent } from './DateTimeGenericComponent'
import { default as PersonComponent } from './PersonComponent'
import { default as BooleanValue } from './BooleanValue'
import { default as BooleanIcon } from './BooleanIcon'
import { default as CalculateTimeString } from './CalculateTimeString'
import { default as GroupChangeType } from './GroupChangeType'
import { default as PeopleDetails } from './PeopleDetails'

const DynamicComponents = {
    ConnectSwitch,
    DummyPasswordBox,
    DateTimeComponent,
    ApplicationOwner,
    ApplicationImage,
    HighlightedClickableLabel,
    CheckInButton,
    CheckOutButton,
    DateTimeGenericComponent,
    CredentialStatusButton,
    CredentialLockedBox,
    CredentialRemainingTime,
    PersonComponent,
    BooleanValue,
    BooleanIcon,
    CalculateTimeString,
    GroupChangeType,
    PeopleDetails,
}

export default DynamicComponents

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled, createStyles, makeStyles } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { ItemDetails, DeleteCredential } from 'components'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import CheckIn from 'components/DynamicComponents/CheckIn'
import CheckOut from 'components/DynamicComponents/CheckOut'
import { CredentialsActionsList } from 'components/CredentialsActions/CredentialsActionsList'
import { CheckoutHistory } from './CheckoutHistory'
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '16px',
            [theme.breakpoints.up('sm')]: {
                height: '80px',
                alignItems: 'center',
                position: 'relative',
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                paddingTop: '16px !important',
                paddingBottom: '16px !important',
            },
        },
    }),
)

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const CertificateDetails = ({ certificate }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState('overview')

    return (
        <>
            {!certificate ? (
                <Loader />
            ) : (
                <>
                    <ItemDetails.Tabs
                        variant="standard"
                        value={activeTab}
                        onChange={(_, value) => setActiveTab(value)}
                    >
                        <ItemDetails.Tabs.Tab
                            value="overview"
                            label={t('Overview')}
                        />
                        <ItemDetails.Tabs.Tab
                            value="additionalInformation"
                            label={t('MoreInformation')}
                        />
                        <ItemDetails.Tabs.Tab
                            value="checkouts"
                            label={t('Checkouts')}
                        />
                    </ItemDetails.Tabs>

                    {activeTab === 'overview' && (
                        <OverviewSection certificate={certificate} />
                    )}
                    {activeTab === 'additionalInformation' && (
                        <MoreInformationSection certificate={certificate} />
                    )}
                    {activeTab === 'checkouts' && (
                        <CheckoutHistory certificate={certificate} />
                    )}
                    {!certificate.isExpired && (
                        <>
                            <CredentialsActionsList item={certificate} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <PaddedDiv className={classes.root}>
                                    {certificate.canCheckOut && (
                                        <CheckOut
                                            data={{ item: certificate }}
                                            drawerView={true}
                                        />
                                    )}
                                    {certificate.canCheckIn && (
                                        <CheckIn
                                            data={{ item: certificate }}
                                            drawerView={true}
                                        />
                                    )}
                                    <Box style={{ marginLeft: !certificate.canCheckIn && !certificate.canCheckOut ? '-40px ' : '0px' }}>
                                        <DeleteCredential
                                            item={certificate}
                                            drawerView={true}
                                        />
                                    </Box>
                                </PaddedDiv>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default CertificateDetails

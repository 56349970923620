import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { ListItem as MuiListItem, styled, withStyles } from '@material-ui/core'

const Item = withStyles({
    root: (props) => ({
        padding: '5px 15px',
        margin: '0px 0px',
        border: props.active ? 'solid 1px #d6d6d6' : 'solid 1px transparent',
        backgroundColor: props.active ? '#f8f8f8' : 'transparent',
        borderRadius: '3px',

        '&:hover': {
            backgroundColor: '#f8f8f8',
            borderRadius: '5px',
            border: 'solid 1px #d6d6d6',
        },
    }),
})(MuiListItem)

const LinkWrapper = styled(({ active, ...rest }) => <RouterLink {...rest} />)(
    ({ active, styles }) => ({
        textDecoration: 'none',
        color: '#8b909a',
        fontWeight: 'normal',
        fontSize: '13px',
        textTransform: 'capitalize',
        ...styles,
    }),
)

const ListItem = ({ children, ...rest }) => {
    return (
        <LinkWrapper {...rest}>
            <Item {...rest}>{children}</Item>
        </LinkWrapper>
    )
}

export default ListItem

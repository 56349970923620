import { FC } from 'react'
import {
    Button as MuiButton,
    createStyles,
    makeStyles,
} from '@material-ui/core'
import { Loader } from 'packages/eid-ui'

const useDefaultStyles = makeStyles(
    createStyles({
        root: {
            // minWidth: '177px',
            height: '31px',

            border: `solid 1px #307fc1`,
            borderRadius: '16px',

            backgroundColor: '#ffffff',
            color: '#307fc1',

            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',
        },
    }),
)
const useGreenStyles = makeStyles(
    createStyles({
        root: {
            // minWidth: '177px',
            height: '31px',

            borderRadius: '16px',

            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',

            backgroundColor: 'transparent',
            border: 'solid 1px #01ae8f',

            color: '#01ae8f',
            '&:hover': {
                backgroundColor: '#01ae8f',
                color: 'white',
            },
        },
    }),
)

const useBlueStyles = makeStyles(
    createStyles({
        root: {
            // minWidth: '177px',
            height: '31px',

            borderRadius: '16px',

            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',

            backgroundColor: 'transparent',
            border: 'solid 1px #307fc1',

            color: '#307fc1',
            '&:hover': {
                backgroundColor: '#307fc1',
                color: 'white',
            },
        },
    }),
)

export interface ButtonProps {
    onClick: any
    isLoading?: boolean
    disabled?: boolean
    variant?: string
    endIcon?: any
}

export const Button: FC<ButtonProps> = (props) => {
    const blueClasses = useBlueStyles()
    const greenClasses = useGreenStyles()
    const defaultClasses = useDefaultStyles()

    function getStylingClasses(variant?: string) {
        switch (variant) {
            case 'blue':
                return blueClasses
            case 'green':
                return greenClasses
            default:
                return defaultClasses
        }
    }
    let classes = getStylingClasses(props.variant)

    return (
        <MuiButton
            size="small"
            classes={classes}
            variant="outlined"
            onClick={props.onClick}
            disabled={props.disabled}
            endIcon={props.endIcon}
        >
            {props.isLoading ? <Loader size={0.6} /> : props.children}
        </MuiButton>
    )
}

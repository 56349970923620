import { Button as MuiButton, makeStyles } from '@material-ui/core'
import SearchButton from './SearchButton'
import FiltersButton from './FiltersButton'
import ApplyFiltersButton from './ApplyFiltersButton'
import BackButton from './BackButton'
import SortButton from './SortButton'
import DetailsButton from './DetailsButton'
import DeleteButton from './DeleteButton'
import { Loader } from 'packages/eid-ui'
import RequestAccessButton from './RequestAccessButton'
import CheckInButton from './CheckInButton'

const styles = {
    small: {
        padding: '3.25px 20px',
        minHeight: '35px',
    },
    medium: {
        padding: '5.25px 15px',
        minHeight: '39px',
    },
    large: {
        padding: '9.25px 30px',
        minHeight: '47px',
    },
}

const useStyles = makeStyles(() => ({
    root: (props) => ({
        textTransform: 'capitalize',
        fontSize: '16px',
        border: '1px solid transparent',
        borderRadius: '5px',
        ...props.variantStyles,
        ...props.style,
    }),
    startIcon: (props) => ({
        marginRight: props.size === 'large' ? '14px' : '10px',
    }),

    disabled: (props) => ({
        ...props.disabledStyles,
    }),
}))

const Button = (props) => {
    const {
        size,
        children,
        style,
        disabledStyles,
        loading,
        loaderColor,
        startIcon,
        endIcon,
        onClick,
        ...rest
    } = props
    const variantStyles = styles[size]

    const classes = useStyles({ style, variantStyles, size, disabledStyles })

    return (
        <MuiButton
            startIcon={!loading && startIcon}
            endIcon={!loading && endIcon}
            size={size}
            classes={classes}
            onClick={!loading ? onClick : undefined}
            {...rest}
        >
            {!loading ? (
                children
            ) : (
                <Loader
                    color={loaderColor ? loaderColor : '#ffffff'}
                    size={0.6}
                    height="0.5inh"
                />
            )}
        </MuiButton>
    )
}

Button.Search = SearchButton
Button.Filters = FiltersButton
Button.ApplyFilters = ApplyFiltersButton
Button.BackButton = BackButton
Button.Sort = SortButton
Button.Details = DetailsButton
Button.Delete = DeleteButton
Button.RequestAccess = RequestAccessButton
Button.CheckIn = CheckInButton

export default Button

import React from 'react'
import moment from 'moment'

const DateTimeComponent = ({ data }) => {
    const { item } = data
    return (
        <>
            {!item?.endDateTimeUtc
                ? '-'
                : moment
                      .utc(item?.endDateTimeUtc)
                      .clone()
                      .local()
                      .format('L LT')}
        </>
    )
}
export default DateTimeComponent

import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import { Button, DeleteCredential, MasterPasswordLock } from 'components'
import {
    Attribute,
    AttributeLabel,
    AttributeValue,
} from 'packages/eid-ui'
import DynamicComponents from 'components/DynamicComponents'
import { CredentialsActionsButton } from 'components/CredentialsActions/CredentialsActionsButton'


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: '16px',
            position: 'unset !important',
            margin: '16px',
        },
        actionArea: {
            width: '100%',
            display: 'flex',
            marginTop: '35px',
            marginBottom: '35px',
        },

        borderBox: {
            marginBottom: '-16px',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0)) !important',
            borderImageSlice: `${1} !important`,
            borderBottom: '1px solid !important',
            opacity: '0.25',
        },
        friendlyName: {
            fontWeight: 'bold',
            fontSize: '14px',
        },

        imageSkeleton: {},
        attributeSkeletonContainer: {},
        attributeSkeleton: {},
    }),
)

const CertificateCard = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { item: data, loading, attributes } = props
    const history = useHistory()

    if (loading)
        return (
            <Card className={classes.root}>
                <SkeletonLoader />
            </Card>
        )
    const renderComponent = (componentName, item) => {
        const attribute = attributes.find(
            (a) => a.component?.componentName === componentName,
        )

        if (componentName === 'actionButtons') {
            return (
                <Fragment>
                    <Button.Details
                        item={item}
                        onClick={() => {
                            history.push(
                                `${history.location.search}&certificateId=${item?.id}`,
                            )
                        }}
                    />
                    <span style={{ marginRight: '30px' }} />
                    <DeleteCredential item={item} />
                    <span style={{ marginRight: '30px' }} />
                    <CredentialsActionsButton item={item} />

                </Fragment>
            )
        } else {
            const DynamicComponentToRender = DynamicComponents[componentName]
            return (
                <DynamicComponentToRender
                    attribute={attribute}
                    data={{ item }}
                />
            )
        }
    }

    return (
        <Card className={classes.root}>
            <Attribute style={{ marginTop: 10 }}>
                <AttributeValue className={classes.friendlyName}>
                    {data.friendlyName}
                </AttributeValue>
            </Attribute>

            <Attribute style={{ marginTop: 15 }}>
                <AttributeLabel>{t('Owner')}</AttributeLabel>
                <AttributeValue>
                    {renderComponent('ApplicationOwner', data)}
                </AttributeValue>
            </Attribute>
            <div style={{ position: 'relative' }}>
                <MasterPasswordLock style={{
                    height: 140,
                    width: '110%',
                    marginLeft: '-20px',
                }}
                />
                <div>
                    <Attribute>
                        <AttributeLabel>{t('Password')}</AttributeLabel>
                        <AttributeValue>
                            {renderComponent('DummyPasswordBox', data)}
                        </AttributeValue>
                    </Attribute>
                    <Box style={{ display: 'flex' }}>
                        <Box style={{ width: '40%' }}>
                            <Attribute>
                                <AttributeLabel>{t('CheckIn')}</AttributeLabel>
                                <AttributeValue>
                                    <Box width='60px'>{renderComponent('CheckInButton', data)}</Box>
                                </AttributeValue>
                            </Attribute>
                        </Box>
                        <Box>
                            <Attribute>
                                <AttributeLabel>{t('Status')}</AttributeLabel>
                                <AttributeValue>
                                    <Box width='100px'>{renderComponent('CheckOutButton', data)}</Box>
                                </AttributeValue>
                            </Attribute>
                        </Box>
                    </Box>
                </div>
            </div>
            <Box>
                <AttributeLabel>{t('EndDate')}</AttributeLabel>
                <AttributeValue>
                    {renderComponent('DateTimeComponent', data)}
                </AttributeValue>
            </Box>
            <Box className={classes.actionArea}>
                <Box>
                    <AttributeValue>
                        {renderComponent('actionButtons', data)}
                    </AttributeValue>
                </Box>
            </Box>

            <Box className={classes.borderBox} />
        </Card>
    )
}

const SkeletonLoader = () => {
    return (
        <Fragment>
            <Skeleton animation="wave" variant="rect" height={80} />
            <Box marginTop={2} padding={2} width="100%">
                <Skeleton variant="rect" animation="wave" height={8} />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    style={{ height: 96, marginTop: 8 }}
                />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height={48}
                    style={{ marginTop: 16 }}
                />
            </Box>
        </Fragment>
    )
}
export default CertificateCard

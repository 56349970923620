const attributes = [
    {
        hideValue: true,
        component: {
            componentName: 'ApplicationImage',
            name: 'applicationLogoImageUrl',
        },
        styles: {
            width: '50px',
            minWidth: '50px',
            maxWidth: '50px',
        },

        headerCellStyles: {
            width: '80px',
            minWidth: '80px',
            maxWidth: '80px',
        },
    },
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        hideValue: true,
        label: 'FriendlyName',
        component: {
            componentName: 'HighlightedClickableLabel',
            queryParam: 'applicationId',
        },
        headerCellStyles: {
            width: '160px',
            minWidth: '160px',
            maxWidth: '100px',
        },
        styles: {
            width: '160px',
            maxWidth: '160px',
            color: '#282828',
            fontSize: '13px',
        },
    },

    {
        name: 'name',
        sortable: true,
        sortBy: 'Name',
        label: 'TechnicalName',
        headerCellStyles: {
            width: '160px',
            minWidth: '160px',
            maxWidth: '160px',
        },
        styles: {
            width: '160px',
            minWidth: '160px',
            maxWidth: '160px',
            fontSize: '13px',
        },
        className: 'bold',
        requireAccess: {
            control: 'ResourceAdmin-Application-List-TechnicalName',
        },
    },
    {
        name: 'resourceSystemFriendlyName',
        sortable: true,
        sortBy: 'resourceSystemFriendlyName',
        label: 'ResourceSystem',
        headerCellStyles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
        },
        styles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-Application-List-ResourceSystem',
        },
    },
    {
        name: 'ownerFriendlyName',
        sortable: true,
        hideValue: true,
        sortBy: 'ownerFriendlyName',
        label: 'ResponsibleParty',
        component: {
            componentName: 'ApplicationOwner',
        },
        headerCellStyles: {
            width: '170px',
            minWidth: '170px',
            maxWidth: '170px',
            fontSize: '13px',
        },
        styles: {
            minWidth: '170px',
            maxWidth: '170px',
            width: '170px',
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-Application-List-ResponsibleParty',
        },
    },
    {
        name: 'description',
        sortable: true,
        sortBy: 'description',
        label: 'Description',

        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
            fontSize: '13px',
        },
        styles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        hideValue: true,
        component: {
            componentName: 'actionButtons',
        },
        styles: {
            minWidth: '200px',
            maxWidth: '200px',
        },

        headerCellStyles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
        },
    },
]

export default attributes

export const initialState = {
    sortOrder: null,
    sortBy: null,
    sortLabel: null,
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_PROP':
            return {
                ...state,
                page: 1,
                [payload.key]: payload.value,
            }
        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                page: 1,
                ...payload,
            }
        case 'SET_TAGS':
            return {
                ...state,
                tags: payload,
            }
        case 'SET_PAGE':
            return {
                ...state,
                page: payload,
            }

        default:
            return state
    }
}

export default reducer

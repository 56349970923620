import {
    isNilOrEmpty,
    useGetPagesAccess,
    useGetControlsAccess,
} from 'packages/core'
import config from 'config'
export const configuration = {}

export const useConfiguration = () => {
    const pages = useGetPagesAccess()
    const controls = useGetControlsAccess().map((c) => c.name)

    return {}
}
// import computers from './computers'
export const SubPathLevel = config.APP_SUBPATH
    ? config.APP_SUBPATH.split('/').length - 1
    : 0

const appConfiguration = {}

export default appConfiguration

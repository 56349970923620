import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'
import { useApplicationRoles } from 'hooks'
import { useEidGrid, CardViewLoader } from 'components'
import { RoleRow, RolesTable, RolesTableHead } from './RolesTableComponent'
import rolesAttributes from './rolesAttributes'
// import RoleCard from './RolesCard'  Enable after mobile UX is done

const ApplicationRoles = ({ id }) => {
    const { t } = useTranslation()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationRoles(id)

    const isSmallScreen = useIsSmallScreen()

    const defaultView = isSmallScreen ? 'card' : 'table'
    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoRolesMessage'),
        pagination,
        attributes: rolesAttributes,
        // cardViewConfig: { TODO: Enable after Mobile UX is done
        //     LoadingIndicatorComponent: CardViewLoader,
        //     CardComponent: RoleCard,
        // },
        tableViewConfig: {
            TableComponent: RolesTable,
            TableHeadComponent: RolesTableHead,
            TableRowComponent: RoleRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    return <>{viewToRender}</>
}

export default ApplicationRoles

import { isNilOrEmpty } from 'packages/core'
import config from 'config'

const statusColors = {
    approved: '#1db57a',
    rejected: '#d0021b',
    open: '#d66a00',
}

export const subHeaderHeight = 70
export const smallScreenWidth = '1022px'
export const tabletScreenWidth = '768px'
export const mobileScreenWidth = '767px'

export const getFullImageUrl = (imagePath: any) =>
    !isNilOrEmpty(imagePath) ? `${config.BASE_EID_URL}${imagePath}` : undefined

export const paginate = (items: any, pageNumber: any, pageSize: any) => {
    const startIndex = (pageNumber - 1) * pageSize
    const end = startIndex + pageSize
    items = items.slice(startIndex, end)
    return items
}
export const getStatusColor = (status: any, defaultColor = '#ffffff') => {
    return Object.keys(statusColors).includes(status)
        ? //@ts-ignore
          statusColors[status]
        : defaultColor
}

export const getMasterLockFieldsWidth = (headings: any, tableHeadingRefs: any) => {
    let allRefWidth = 0
    const masterLockFields = headings.filter((x: { showMasterLock: any }) => x.showMasterLock)
    if (masterLockFields?.length > 0) {
        masterLockFields.forEach((item: { name: any }) => {
            const itemIndex = headings.findIndex((x: { name: any }) => x.name === item.name)
            if (itemIndex >= 0) {
                allRefWidth = tableHeadingRefs.current[itemIndex]?.current?.offsetWidth ? allRefWidth + tableHeadingRefs.current[itemIndex].current.offsetWidth : allRefWidth
            }
        });
    }
    return allRefWidth
}

export const getMasterLockLeftSpace = (headings: any, tableHeadingRefs: any) => {
    let allRefOnLeftWidth = 0
    let keysBeforeMasterPass: any[] = []
    let isMasterLockFound = false
    headings.forEach((element: { showMasterLock: any; name: any }) => {
        if (!element.showMasterLock && !isMasterLockFound) {
            keysBeforeMasterPass.push(element.name)
        } else {
            isMasterLockFound = true
        }
    });
    keysBeforeMasterPass.forEach(item => {
        const itemIndex = headings.findIndex((x: { name: any }) => x.name === item)
        if (itemIndex >= 0) {
            allRefOnLeftWidth = tableHeadingRefs.current[itemIndex]?.current?.offsetWidth ? allRefOnLeftWidth + tableHeadingRefs.current[itemIndex].current.offsetWidth : allRefOnLeftWidth
        }
    });
    return allRefOnLeftWidth
}


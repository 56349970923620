import {
    MenuItem,
    makeStyles,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core'
import useSubcomponents from 'useSubcomponents'

const useStyles = makeStyles({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
    },
    icon: {
        minWidth: '40px',
        color: 'black',
    },
    selected: {
        backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        borderLeft: '2px solid #307fc1',
        '& img': {
            opacity: 1,
            filter: 'grayscale(0%) ',
        },
    },
})

const TabsItem = ({ label, icon, selected, onClick }) => {
    const classes = useStyles()
    return (
        <MenuItem onClick={onClick} classes={classes} selected={selected}>
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    )
}

const LeftPaneTabs = ({ active, onChange, tabs }) => {
    const { controls } = useSubcomponents()

    const isAccessible = (tab) => {
        return controls.includes(tab.requireAccess?.control)
    }

    return (
        <>
            {tabs &&
                tabs
                    .filter((x) => x.display === true)
                    .filter((y) => {
                        if (y.requireAccess) {
                            return isAccessible(y)
                        } else {
                            return true
                        }
                    })
                    .map((tab, index) => (
                        <TabsItem
                            key={`${tab.value}_${index}`}
                            label={tab.label}
                            onClick={() => {
                                onChange(tab.value)
                            }}
                            selected={active === tab.value}
                            icon={tab.icon}
                        ></TabsItem>
                    ))}
        </>
    )
}

export default LeftPaneTabs

import { FC, useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { EnterPassword } from './EnterPassword'
import { ResetConfrimation } from './ResetConfrimation'
import { SetPassword } from './SetPassword'
import ItemDetails from 'components/ItemDetails'
import { useTranslation } from 'react-i18next'
import { useAppState } from 'appContext'
import { useValidateMasterPassword } from 'hooks'
import { isNilOrEmpty } from 'packages/core'

export interface PasswordModalProps {
    open?: any
    setOpen?: any
    children?: any
}

export const PasswordModal: FC<PasswordModalProps> = (props) => {
    const { t } = useTranslation()
    const { open, setOpen, children } = props
    const [{ masterPassword }, dispatch]: any = useAppState()
    const [enteredPassword, setEnteredPassword] = useState('')
    const { data: validateMasterPassword, isLoading } =
        useValidateMasterPassword(enteredPassword)
    const [isInvalidPassword, setIsInvalidPassword] = useState(false)

    useEffect(() => {
        if (validateMasterPassword) {
            if (validateMasterPassword.isValid) {
                setIsInvalidPassword(false)
                setOpen('addToCart')
                dispatch({
                    type: 'SET_MASTER_PASSWORD',
                    payload: enteredPassword,
                })
            } else {
                setIsInvalidPassword(true)
            }
        }
    }, [validateMasterPassword])

    const handleClose = () => {
        setIsInvalidPassword(false)
        setOpen('')
    }

    const defaultContent = (
        <Box minWidth="130px" maxWidth="200px" margin="32px">
            <ItemDetails.ActionButton
                fontColor="#ffffff"
                bgColor={'#307fc1'}
                width="100%"
                onClick={() =>
                    setOpen(masterPassword ? 'addToCart' : 'enterPassword')
                }
            >
                {t('RequestAccess')}
            </ItemDetails.ActionButton>
        </Box>
    )
    return (
        <>
            {children ? children : defaultContent}
            {open === 'enterPassword' && (
                <EnterPassword
                    loading={isLoading}
                    error={isInvalidPassword}
                    open={open === 'enterPassword'}
                    onClose={handleClose}
                    onReset={() => setOpen('resetConfrimation')}
                    onCancel={handleClose}
                    onSucces={(val: string) => {
                        if(isNilOrEmpty(val)){
                            setIsInvalidPassword(true)
                        }
                        else{
                        setIsInvalidPassword(false)
                        setEnteredPassword(val)
                        }
                    }}
                />
            )}

            {open === 'resetConfrimation' && (
                <ResetConfrimation
                    open={open === 'resetConfrimation'}
                    onClose={() => setOpen('')}
                    onReset={() => setOpen('setPassword')}
                    onCancel={() => setOpen('enterPassword')}
                />
            )}

            {open === 'setPassword' && (
                <SetPassword
                    open={open === 'setPassword'}
                    onClose={() => setOpen('')}
                    onCancel={() => setOpen('enterPassword')}
                />
            )}
        </>
    )
}

const rolesAttributes = [
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        label: 'DisplayName',

        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
            fontSize: '13px',
        },
        styles: {
            width: '150px',
            maxWidth: '150px',
            minWidth: '150px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'description',
        sortable: true,
        sortBy: 'description',
        label: 'Description',
        headerCellStyles: {
            width: '162px',
            minWidth: '162px',
            maxWidth: '162px',
            fontSize: '13px',
        },
        styles: {
            minWidth: '162px',
            maxWidth: '162px',
            width: '162px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'rightAllowedAssigeeTypeFriendlyName',
        sortBy: 'rightAllowedAssigeeTypeFriendlyName',
        sortable: true,
        label: 'AllowedMemberTypes',
        headerCellStyles: {
            width: '160px',
            minWidth: '160px',
            maxWidth: '160px',
            fontSize: '13px',
        },
        styles: {
            width: '160px',
            minWidth: '160px',
            maxWidth: '160px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'name',
        sortBy: 'name',
        label: 'Value',
        sortable: true,

        styles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
            fontSize: '13px',
        },

        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
            fontSize: '13px',
        },
    },
    {
        name: 'id',
        sortBy: 'id',
        label: 'ID',
        sortable: true,

        styles: {
            width: '120px',
            minWidth: '120px',
            maxWidth: '120px',
            fontSize: '13px',
        },

        headerCellStyles: {
            width: '120px',
            minWidth: '120px',
            maxWidth: '120px',
            fontSize: '13px',
        },
    },
    //TODO: Data is not present in the DB
    // {
    //     name: 'state',
    //     hideValue: true,
    //     sortable: true,
    //     sortBy: 'state',
    //     label: 'State',
    //     component: {
    //         componentName: 'ScopeState',
    //     },
    //     headerCellStyles: {
    //         width: '120px',
    //         minWidth: '120px',
    //         maxWidth: '120px',
    //         fontSize: '13px',
    //     },
    //     styles: {
    //         width: '120px',
    //         minWidth: '120px',
    //         maxWidth: '120px',
    //         color: '#282828',
    //         fontSize: '13px',
    //     },
    // },
    {
        hideValue: true,
        component: {
            componentName: 'actionButtons',
        },
        styles: {
            width: '240px',
            minWidth: '240px',
            maxWidth: '240px',
        },

        headerCellStyles: {
            width: '240px',
            minWidth: '240px',
            maxWidth: '240px',
        },
    },
]

export default rolesAttributes

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'
import { useApplicationSecrets } from 'hooks'
import { useEidGrid, CardViewLoader } from 'components'
import {
    SecretRow,
    SecretsTable,
    SecretsTableHead,
} from './SecretsTableComponent'
import secretsAttributes from './secretsAttributes'
import SecretCard from './SecretCard'
import { useSecretsContext } from 'secretsContext'

const ApplicationSecrets = ({ azureApplicationId }) => {
    const { t } = useTranslation()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationSecrets(azureApplicationId)

    const isSmallScreen = useIsSmallScreen()
    const [state] = useSecretsContext()

    const defaultView = isSmallScreen ? 'card' : 'table'

    const masterPasswordLockStyle = {
        height: pagination ? 'calc(100% - 96px)' : 'calc(100% - 64px)',
        width: state.masterPasswordWidth,
        left: state.masterPasswordLeftWidth,
    }

    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoSecretsMessage'),
        pagination,
        attributes: secretsAttributes,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: SecretCard,
        },
        tableViewConfig: {
            TableComponent: SecretsTable,
            TableHeadComponent: SecretsTableHead,
            TableRowComponent: SecretRow,
            showMasterPasswordLock: true,
            masterPasswordLockStyle,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    return <>{viewToRender}</>
}

export default ApplicationSecrets

import React, { useReducer, createContext, useContext } from 'react'
import reducer, { initialState } from './reducer'

export const ScopesContext = createContext({})

export const ScopesContextProvider = ({ children }) => (
    <ScopesContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </ScopesContext.Provider>
)

export const useScopesContext = () => useContext(ScopesContext)

import { useQuery, useMutation, queryCache } from 'react-query'
import { useAxios } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useNotification } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import { useSecretsContext } from 'secretsContext'
import { useCertificatesContext } from 'certificatesContext'
import { useScopesContext } from 'scopesContext'
import { useAppRolesContext } from './../appRolesContext/appRolesContext'
import { usePermissionsContext } from './../permissionsContext/permissionsContext'
export const APPLICATIONS_KEY = 'APPLICATIONS'

export const useApplication = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applications/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useOwnedApplications = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        status,
        itemType,
        accountStore,
        accountStoreUsageType,
        ownedBy,
        shouldLoadTags,
        advancedSearchTags,
        showOnlyAzureApplications,
    } = state
    const ownerPersonId = ownedBy?.id
    const description = state['advancedSearch.forms.description']
    const friendlyName = state['advancedSearch.forms.friendlyName']

    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (accountStoreUsageType) {
        queryData.accountStoreUsageTypeId = accountStoreUsageType.id
    }
    if (accountStore) {
        queryData.accountStoreId = accountStore.id
    }

    if (shouldLoadTags) {
        queryData.includeTags = true
    }

    if (advancedSearchTags) {
        queryData.tags = advancedSearchTags
    }

    const advancedSearch = []
    if (description) {
        advancedSearch.push({
            name: 'Description',
            type: 'string',
            value: description,
        })
    }
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }

    queryData.advancedSearch = advancedSearch

    const endpoint = ownerPersonId
        ? `/api/applications/owned/${ownerPersonId}`
        : `api/applications/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATIONS',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            status,
            itemType,
            description,
            friendlyName,
            ownerPersonId,
            accountStore,
            accountStoreUsageType,
            advancedSearchTags,
            shouldLoadTags,
            showOnlyAzureApplications,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    showOnlyAzureApplications,
                },
            })
        },
    )
}

export const useAzureApplicationOwnersAndDeputies = (id: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS_AND_DEPUTIES', id],
        () =>
            callApi({
                method: 'GET',
                url: `api/applications/OwnersDeputies/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationWorkflows = (
    noun: string,
    verb: string,
    id?: string,
) => {
    const callApi = useAxios()
    return useQuery([APPLICATIONS_KEY, 'WORKFLOWS', id, noun, verb], () =>
        callApi({
            method: 'GET',
            url: `api/UIActions/byNoun?noun=${noun}&verb=${verb}`,
        }).then((data) => data.data),
    )
}

export const useApplicationCertificates = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = useCertificatesContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    const endpoint = `/api/certificates/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_CERTIFICATES',
            sortBy,
            sortOrder,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}

export const useApplicationSecrets = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = useSecretsContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    const endpoint = `/api/secrets/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_SECRETS',
            sortBy,
            sortOrder,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}

export const useApplicationSecret = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'SECRET', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/secrets/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationCertificate = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'CERTIFICATE', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/certificates/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationCredentialsHiddenProps = (
    id: any,
    masterPassword: any,
    isEnabled: true,
) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'SECRET_PROPS', id, isEnabled],
        () =>
            callApi({
                method: 'POST',
                url: `/api/common/hiddenProperties/${id}`,
                data: `"${masterPassword}"`,
            }).then((data) => data.data),
        {
            enabled: Boolean(masterPassword) && isEnabled,
        },
    )
}

export const refreshListings = () => {
    queryCache.invalidateQueries(
        (c) =>
            c.queryKey.includes('LIST_MY_APPLICATION_SECRETS') ||
            c.queryKey.includes('LIST_MY_APPLICATION_CERTIFICATES'),
    )
}

export const refreshPermissionsListing = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('LIST_MY_APPLICATION_PERMISSIONS'),
    )
}

export const refreshScopesListing = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('LIST_MY_APPLICATION_SCOPES'),
    )
}

export const refreshAppRolesListing = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('LIST_MY_APPLICATION_ROLES'),
    )
}

export const refreshCredentialDetails = (credentialId: any) => {
    queryCache.invalidateQueries((c) => c.queryKey.includes(credentialId))
}

export const useCheckInCredential = (id: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (checkOutId) =>
            callApi({
                method: 'POST',
                url: `/api/common/checkin/${checkOutId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('CredentialCheckedIn'))
                refreshListings()
                refreshCredentialDetails(id)
            },
            onError: () => {
                showWarningMessage(t('CredentialNotCheckedIn'))
            },
        },
    )
}

export const useCheckOutCredential = (id: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/common/checkout',
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('CredentialCheckedOut'))
                refreshListings()
                refreshCredentialDetails(id)
            },
            onError: () => {
                showWarningMessage(t('CredentialNotCheckedOut'))
            },
        },
    )
}

export const useDeleteCredential = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (credentialId) =>
            callApi({
                method: 'DELETE',
                url: `/api/common/${credentialId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('CredentialDeleted'))
                refreshListings()
            },
            onError: () => {
                showWarningMessage(t('CredentialNotDeleted'))
            },
        },
    )
}
export const useCheckOutCredentialsRecords = (
    CredentialId: any,
    queryData: any,
) => {
    const callApi = useAxios()
    const endpoint = `api/common/checkOutCredentialsRecordsbri`
    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'CHECKOUT_CREDENTIAL_RECORDS',
            queryData,
            CredentialId,
        ],

        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take: 15,
                    CredentialId,
                },
            })
        },
    )
}
export const useApplicationScopes = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = useScopesContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    const endpoint = `/api/LocalRights/scopes`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_SCOPES',
            sortBy,
            sortOrder,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}
export const useApplicationScope = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'SCOPE', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/LocalRights/scopes/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}
export const useApplicationRoles = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = useAppRolesContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    const endpoint = `/api/LocalRights/appRoles`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_ROLES',
            sortBy,
            sortOrder,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}
export const useApplicationRole = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'APPROLE', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/LocalRights/appRoles/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}
export const useApplicationPermissions = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = usePermissionsContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    const endpoint = `/api/permissions/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_PERMISSIONS',
            sortBy,
            sortOrder,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}
export const useApplicationPermission = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'PERMISSION', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/permissions/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useDeletePermission = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (permissionId) =>
            callApi({
                method: 'DELETE',
                url: `/api/permissions/${permissionId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('PermissionDeleted'))
                refreshPermissionsListing()
            },
            onError: () => {
                showWarningMessage(t('PermissionNotDeleted'))
            },
        },
    )
}

export const useDeleteScope = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (globalRightId) =>
            callApi({
                method: 'DELETE',
                url: `/api/LocalRights/scopes/${globalRightId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ScopeDeleted'))
                refreshScopesListing()
            },
            onError: () => {
                showWarningMessage(t('ScopeNotDeleted'))
            },
        },
    )
}

export const useDeleteAzureApplicationRole = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (globalRightId) =>
            callApi({
                method: 'DELETE',
                url: `/api/LocalRights/appRoles/${globalRightId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('AppRoleDeleted'))
                refreshAppRolesListing()
            },
            onError: () => {
                showWarningMessage(t('AppRoleNotDeleted'))
            },
        },
    )
}

import { RiskIndicator } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import TableGrid from 'components/TableGrid'
import { Box, makeStyles, createStyles } from '@material-ui/core'
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '80px',
        },
    }),
)
export const LocalSensitiveFunctions = ({
    isLoading,
    isLoadingMore,
    data,
    pagination,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const attributes = [
        {
            name: 'name',
            label: t('FriendlyName'),
        },
        {
            name: 'functionType',
            label: t('FunctionType'),
        },
        {
            name: 'description',
            label: t('Description'),
        },
        {
            label: t('RiskLevel'),

            resolve: (item) => (
                <RiskIndicator
                    label={item.riskLevel}
                    variant={item.riskLevel}
                    className={classes.root}
                />
            ),
        },
    ]
    return (
        <>
            <TableGrid
                loading={isLoading}
                data={data}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
            />
            <Box style={{ justfyContent: 'center', display: 'flex' }}>
                {pagination}
            </Box>{' '}
        </>
    )
}

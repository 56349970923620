import React from 'react'
import { GlobalMenu } from 'packages/eid-ui'
import { useAllApplications } from 'hooks'

const appNameMaps = {
    EIDEmpowerIDWeb: 'EmpowerID',
    EIDAzureAnalyticsMS: 'AzureAnalytics',
    EIDITShopMS: 'ITShop',
    EIDMyIdentity: 'MyIdentity',
    EIDMyTasks: 'MyTasks',
    EIDResourceAdminMS: 'ResourceAdmin',
    EIDRiskAnalyticsMS: 'RiskAnalytics',
}

const DesktopGlobalMenu = () => {
    const { data, isLoading } = useAllApplications()

    const eidApp = data?.find((a) => a.applicationName === 'EIDEmpowerIDWeb')

    return (
        <GlobalMenu
            loading={isLoading}
            eid={
                eidApp
                    ? {
                          name: appNameMaps[eidApp.applicationName],
                          displayName: eidApp.applicationDisplayName,
                          url: eidApp.applicationAbsoluteUrl,
                      }
                    : undefined
            }
            apps={data
                ?.filter((f) => f.applicationName !== 'EIDEmpowerIDWeb')
                .map((a) => ({
                    name: appNameMaps[a.applicationName],
                    displayName: a.applicationDisplayName,
                    url: a.applicationAbsoluteUrl,
                }))}
            current="ResourceAdmin"
        />
    )
}

export default DesktopGlobalMenu

import { useState } from 'react'
import {
    makeStyles,
    MenuItem,
    ListItemText,
    Typography,
} from '@material-ui/core'
import { ArrowSmallIcon } from 'packages/eid-icons'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import config from 'config'

const useStyles = makeStyles({
    menuItem: {
        minWidth: '177px',
        maxHeight: '30px',
        margin: '5px 0px 5px 0px',
        borderRadius: '3px',
        '&:hover': {
            backgroundImage: 'linear-gradient(99deg, #019cae, #307fc1 100%)',
            color: '#fff',
        },
        whiteSpace: 'normal',
    },
})

export const ActionItem = ({ actionItem, application }) => {
    const classes = useStyles()

    const [actionsButtonIconColor, setActionsButtonIconColor] =
        useState('#307fc1')

    const query = useQuery()
    const history = useHistory()

    const onClick = (name) => {
        query.set('workflowName', name)
        query.set('resourceID', `${application.resourceID}`)
        history.push(
            `${config.APP_SUBPATH}/applications/workflows?${query.toString()}`,
        )
    }

    return (
        <MenuItem
            key={actionItem.id}
            onClick={() => {
                onClick(actionItem.requestWorkflowName)
            }}
            onMouseEnter={() => {
                setActionsButtonIconColor('white')
            }}
            onMouseLeave={() => {
                setActionsButtonIconColor('#307fc1')
            }}
            className={classes.menuItem}
        >
            <ListItemText>
                <Typography style={{ fontSize: '12px' }}>
                    {actionItem.friendlyName}
                </Typography>
            </ListItemText>
            <ArrowSmallIcon
                color={actionsButtonIconColor}
                fill={actionsButtonIconColor}
                direction="right"
            />
        </MenuItem>
    )
}

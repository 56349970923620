import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'
import { ItemDetails } from 'components'
import ApplicationOwner from '../../DynamicComponents/ApplicationOwner'
import DummyPasswordBox from 'components/DynamicComponents/DummyPasswordBox'
import moment from 'moment'
import MasterPasswordLock from 'components/MasterPasswordLock'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ secret }) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Application')}
                    value={secret.azureApplicationName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <Box position="relative">
                    <MasterPasswordLock
                        style={{
                            height: '100%',
                            width: useIsSmallScreen() ? '120%' : '110%',
                            marginLeft: '-35px',
                        }}
                    />
                    <ItemDetails.Attribute
                        label={t('Password')}
                        value={
                            <Box display="flex">
                                <DummyPasswordBox data={{ item: secret }} isDetailed />
                            </Box>
                        }
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                </Box>
                <ItemDetails.Attribute
                    label={t('Tenant')}
                    value={secret.azureTenantName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CreatedBy')}
                    value={
                        secret && (
                            <div style={{ marginLeft: '5px' }}>
                                <ApplicationOwner data={{ item: secret }} />
                            </div>
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CreatedDate')}
                    value={moment
                        .utc(secret?.createdDateUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('ExpirationDate')}
                    value={moment
                        .utc(secret?.endDateTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('description')}
                    value={secret?.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

import React, { Fragment, useEffect } from 'react'
import AppRolesLayout from './AppRolesLayout'
import { useAuthState } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { usePageContext } from 'pageContext'

const allFilters = [
    {
        name: 'applicationsOwnedByFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-OwnedBy',
    },

    {
        name: 'targetSystemFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-TargetSystem',
    },
    {
        name: 'applicationsFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-Applications',
    },
    {
        name: 'applicationRolesLocationFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-Location',
    },
    {
        name: 'businessFunctionFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-BusinessFunctions',
    },
    {
        name: 'globalRightsFilter',
        requireAccess: 'ResourceAdmin-Groups-Filter-Rights',
    },
    {
        name: 'applicationRolesAdvancedSearch',
        requireAccess: 'ResourceAdmin-Groups-Filter-AdvancedSearch',
    },
]

const RequestViewAllAppRoles = () => {
    const [state, dispatch] = usePageContext()

    const [{ currentUserId, friendlyName }] = useAuthState()

    const { trimControlsBasedOnAccess } = useSubcomponents()

    const filters = trimControlsBasedOnAccess(allFilters).map((f) => f.name)
    const hasOwnedByFilterAccess =
        filters.indexOf('applicationsOwnedByFilter') >= 0
    useEffect(() => {
        if (!hasOwnedByFilterAccess) {
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'ownedBy',
                    value: {
                        id: currentUserId,
                        friendlyName: friendlyName,
                    },
                },
            })
        }
    }, [currentUserId])
    return (
        <Fragment>
            <AppRolesLayout filters={filters} />
        </Fragment>
    )
}

export { RequestViewAllAppRoles }

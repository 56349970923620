const permissionsAttributes = [
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        label: 'ApiPermissionName',

        headerCellStyles: {
            width: '235px',
            minWidth: '235px',
            maxWidth: '235px',
            fontSize: '13px',
        },
        styles: {
            width: '235px',
            maxWidth: '235px',
            minWidth: '235px',
            color: '#282828',
            fontSize: '13px',
            paddingRight: '10px',
        },
    },
    {
        name: 'typeFriendlyName',
        sortable: true,
        sortBy: 'typeFriendlyName',
        label: 'Type',
        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
            fontSize: '13px',
        },
        styles: {
            minWidth: '150px',
            maxWidth: '150px',
            width: '150px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'description',
        sortBy: 'description',
        sortable: true,
        label: 'Description',
        headerCellStyles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
            fontSize: '13px',
        },
        styles: {
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'consentType',
        sortBy: 'consentType',
        label: 'ConsentType',
        sortable: true,

        styles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
            fontSize: '13px',
        },

        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
            maxWidth: '150px',
            fontSize: '13px',
        },
    },
    {
        hideValue: true,
        component: {
            componentName: 'actionButtons',
        },
        styles: {
            width: '240px',
            minWidth: '240px',
            maxWidth: '240px',
        },

        headerCellStyles: {
            width: '240px',
            minWidth: '240px',
            maxWidth: '240px',
        },
    },
]

export default permissionsAttributes

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled, createStyles, makeStyles } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { ItemDetails, DeleteCredential } from 'components'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import CheckIn from 'components/DynamicComponents/CheckIn'
import CheckOut from 'components/DynamicComponents/CheckOut'
import { CredentialsActionsList } from 'components/CredentialsActions/CredentialsActionsList'
import { CheckoutHistory } from './CheckoutHistory'
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '16px',
            [theme.breakpoints.up('sm')]: {
                height: '80px',
                alignItems: 'center',
                position: 'relative',
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                paddingTop: '16px !important',
                paddingBottom: '16px !important',
            },
        },
    }),
)

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const SecretDetails = ({ secret }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState('overview')

    return (
        <>
            {!secret ? (
                <Loader />
            ) : (
                <>
                    <ItemDetails.Tabs
                        variant="standard"
                        value={activeTab}
                        onChange={(_, value) => setActiveTab(value)}
                    >
                        <ItemDetails.Tabs.Tab
                            value="overview"
                            label={t('Overview')}
                        />
                        <ItemDetails.Tabs.Tab
                            value="additionalInformation"
                            label={t('MoreInformation')}
                        />
                        <ItemDetails.Tabs.Tab
                            value="checkouts"
                            label={t('Checkouts')}
                        />
                    </ItemDetails.Tabs>

                    {activeTab === 'overview' && (
                        <OverviewSection secret={secret} />
                    )}
                    {activeTab === 'additionalInformation' && (
                        <MoreInformationSection secret={secret} />
                    )}
                    {activeTab === 'checkouts' && (
                        <CheckoutHistory secret={secret} />
                    )}
                    {!secret.isExpired && (
                        <>
                            <CredentialsActionsList item={secret} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <PaddedDiv className={classes.root}>
                                    {secret.canCheckOut && (
                                        <CheckOut
                                            data={{ item: secret }}
                                            drawerView={true}
                                        />
                                    )}
                                    {secret.canCheckIn && (
                                        <CheckIn
                                            data={{ item: secret }}
                                            drawerView={true}
                                        />
                                    )}
                                    <Box style={{ marginLeft: !secret.canCheckIn && !secret.canCheckOut ? '-40px ' : '0px' }}>
                                        <DeleteCredential item={secret} drawerView={true} />
                                    </Box>
                                </PaddedDiv>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default SecretDetails

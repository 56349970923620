import DummyPasswordBox from './DummyPasswordBox'
import { useTranslation } from 'react-i18next'
import { TextFormatter } from 'components'

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const displayMessageStyle = {
    minWidth: '170px',
    maxWidth: '170px',
    width: '170px',
    color: '#282828',
    fontSize: '12px',
    paddingLeft: '16px'
}


const CredentialLockedBox = ({ data }) => {
    const { item } = data
    const { t } = useTranslation()

    let displayMessage;

    if (item.canRequestAccess) {
        displayMessage = t('CanRequestAccessMessage')
    }
    else if (item.createdFromAzure) {
        displayMessage = t('CredentialCreatedFromAzureMessage')
    }
    else if (!item.createdFromEIDAndVaulted) {
        displayMessage = t('CredentialNotVaultedMessage')
    }

    if (!item.canCheckIn && !item.canCheckOut && !item.isExpired) {
        return (
            <TextFormatter
                value={displayMessage}
                tooltipProps={tooltipProps}
                maxCharacters={100}
                style={displayMessageStyle}
            />)
    }
    else {
        return <DummyPasswordBox data={data} />
    }
}

export default CredentialLockedBox

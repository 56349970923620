import { useGetControlsAccess, isNilOrEmpty } from 'packages/core'

const useSubcomponents = () => {
    const controls = useGetControlsAccess().map((c) => c.name)

    const trimControlsBasedOnAccess = (controlsList) =>
        controlsList.filter((f) =>
            f.requireAccess ? controls.includes(f.requireAccess) : true,
        )

    const canCheckInActiveCheckouts = controls.includes(
        'ResourceAdmin-CheckInActiveCheckouts-Control',
    )

    const canSeeAtLeastOneFilter = (resourceType) =>
        resourceType.filters.filter((f) =>
            f.requireAccess
                ? controls.indexOf(f.requireAccess.control) >= 0
                : true,
        ).length > 0
    const getAccessBasedAttributes = (attributes) => {
        const result = attributes.filter((a) => {
            if (isNilOrEmpty(a.requireAccess)) {
                return true
            }
            if (controls.findIndex((x) => x === a.requireAccess.control) > -1) {
                return true
            }
            return false
        })

        return result
    }
    return {
        canSeeAtLeastOneFilter,
        canCheckInActiveCheckouts,
        trimControlsBasedOnAccess,
        getAccessBasedAttributes,
        controls,
    }
}
export default useSubcomponents

import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useUiActionsByResource } from 'hooks'
import { Skeleton } from '@material-ui/lab'
import {
    Box,
    makeStyles,
    createStyles,
    Paper,
    Divider,
    MenuList,
} from '@material-ui/core'
import { ActionItem } from './ActionItem'
import Background from '../../../src/assets/images/bg.png'

const useStyles = makeStyles({
    root: {
        minWidth: '177px',
    },

    actionsContainer: {
        flexWrap: 'wrap',
        borderRadius: '8px',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        minWidth: '260px',
        maxWidth: '230px',
    },

    actionsHeader: {
        display: 'flex',
        color: 'white',
        padding: '16px',
        marginBottom: '8px',
        fontSize: '16px',
        fontWeight: 'bold',
        flexDirection: 'row',
        minHeight: '48px',
        maxHeight: '48px',
        backgroundImage: `url(${Background}), linear-gradient(99deg, #019cae, #307fc1 100%)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },

    menu: {
        display: 'flex',
        flexDirection: 'row',
    },

    menuList: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
        maxHeight: '300px',
        overflow: 'auto',
    },

    lineGlow: {
        width: '100%',
        height: '1px',
        opacity: 0.25,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: 1,
    },
})

export type ActionProps = {
    application: {
        id: string
        resourceID: number
    }
}

export const Actions: FC<ActionProps> = (props) => {
    const { t } = useTranslation()
    const UI_ACTIONS_NOUN = 'ResourceAdmin'
    const classes = useStyles()

    const { data, isLoading } = useUiActionsByResource(
        props.application.id,
        UI_ACTIONS_NOUN,
    )

    return (
        <Fragment>
            {isLoading ? (
                <Skeleton height={40} />
            ) : (
                <Fragment>
                    <Paper className={classes.actionsContainer}>
                        <Box className={classes.actionsHeader}>
                            {t('Actions')}
                        </Box>
                        <MenuList className={classes.menuList}>
                            {data &&
                                data.map((d: any) => (
                                    <>
                                        <ActionItem
                                            actionItem={d}
                                            application={props.application}
                                        />
                                        <Divider
                                            style={{
                                                margin: '0px 9px 0px 8px ',
                                            }}
                                        />
                                    </>
                                ))}
                        </MenuList>
                        <div className={classes.lineGlow}></div>
                    </Paper>

                    {/*  TODO: Need to enable when mobile Ui is ready.
                            
                                isSmallScreen ? (
                                    <EidWorkflow
                                        key={d.id}
                                        baseEidUrl={config.BASE_EID_URL}
                                        title={d.friendlyName}
                                        workflowName={d.requestWorkflowName}
                                        workflowParams={[
                                            `SelectedResourceID=${props.application.resourceID}`,
                                        ]}
                                    >
                                        <MenuItem
                                            onClick={closeMenu}
                                            className={classes.menuItem}
                                        >
                                            {d.friendlyName}
                                        </MenuItem>
                                    </EidWorkflow>
                                ) : (
                                    <MenuItem
                                        key={d.id}
                                        onClick={() => {
                                            closeMenu()
                                            onClick(d.requestWorkflowName)
                                        }}
                                        className={classes.menuItem}
                                    >
                                        {d.friendlyName}
                                    </MenuItem>
                                // ),
                            )}
                    </Popover> */}
                </Fragment>
            )}
        </Fragment>
    )
}

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useApplicationCertificates } from 'hooks'
import { useEidGrid, CardViewLoader } from 'components'
import certificatesAttributes from './certificatesAttributes'
import {
    CertificatesTable,
    CertificatesTableHead,
    CertificatesRow,
} from './CertificatesTableComponent'
import { useIsSmallScreen } from 'packages/core'
import CertificateCard from './CertificateCard'
import { useCertificatesContext } from 'certificatesContext'

const ApplicationCertificates = ({ azureApplicationId }) => {
    const { t } = useTranslation()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationCertificates(azureApplicationId)
    const isSmallScreen = useIsSmallScreen()

    const defaultView = isSmallScreen ? 'card' : 'table'
    const [state] = useCertificatesContext()

    const masterPasswordLockStyle = {
        height: pagination ? 'calc(100% - 96px)' : 'calc(100% - 64px)',
        width: state.masterPasswordWidth,
        left: state.masterPasswordLeftWidth,
    }

    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoCertificatesMessage'),
        pagination,
        attributes: certificatesAttributes,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: CertificateCard,
        },
        tableViewConfig: {
            TableComponent: CertificatesTable,
            TableHeadComponent: CertificatesTableHead,
            TableRowComponent: CertificatesRow,
            showMasterPasswordLock: true,
            masterPasswordLockStyle,
        },
        defaultView,
    })
    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])
    return <>{viewToRender}</>
}

export default ApplicationCertificates

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles } from '@material-ui/core'
import { useResultantMembers } from 'hooks'
import { useDebounce } from 'packages/core'
import TableGrid from 'components/TableGrid'
import BooleanIcon from 'components/DynamicComponents/BooleanIcon'
import DateTimeGenericComponent from 'components/DynamicComponents/DateTimeGenericComponent'
import Search from 'components/Filters/Search'


const useTableStyles = makeStyles({
    headGrey: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
        height: '80px'
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 16px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
    },
    tableHeaderCell: {
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: '#7d7c7c',
    },
})

export const ResultantMembers = ({ appRoleId }) => {
    const { t } = useTranslation()
    const tableClasses = useTableStyles()

    const [sorting, setSorting] = useState({ sortOrder: null, sortBy: null })
    const [searchKey, setSearchKey] = useState('')


    const handleSort = (sorting) => {
        setSorting(sorting)
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const debouncedSearchValue = useDebounce(searchKey)


    const queryData = {
        searchTerm: debouncedSearchValue,
        sorting,
    }

    const {
        list,
        loading: isLoading,
        pagination,
    } = useResultantMembers(appRoleId, queryData)

    const attributes = [
        {
            label: t('FriendlyName'),
            name: 'friendlyName',
            sortable: true,
            style: {
                width: '150px',
                color: '#282828',
                fontSize: '13px',
                fontWeight: 'bold'
            },
        },
        {
            label: t('Enabled'),
            name: 'disabled',
            sortable: true,
            resolve: (item) => (
                <BooleanIcon
                    data={{ item }}
                    attribute={{ name: 'disabled' }}
                />
            )
        },
        {
            label: t('Type'),
            name: 'userType',
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            name: 'lastLogonTime',
            label: t('LastLogin'),
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
            resolve: (item) => (
                <DateTimeGenericComponent
                    data={{ item }}
                    attribute={{ name: 'lastLogonTime' }}
                />
            )
        },
        {
            name: 'logonName',
            label: t('LogonName'),
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            name: 'domainOrDirectory',
            label: t('DomainOrDirectory'),
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            }
        },
        {
            name: 'distinguishedName',
            label: t('DistinguishedName'),
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
                width: '350px'
            }
        }
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                paddingBottom="16px"
            >
                <Box width="308px" display="flex" alignItems="center">
                    <Search
                        handleSearch={handleSearchChange}
                        placeholder={t('SearchFilterMembers')}
                        value={searchKey}
                        handleClear={handleClear}
                        outlined
                    />
                </Box>
            </Box>

            <TableGrid
                loading={isLoading}
                data={list}
                attributes={attributes}
                tableClasses={tableClasses}
                sorting={sorting}
                onSort={handleSort}
            />

            <Box display="flex" justifyContent="center">
                <Box style={{ height: '100%' }}>{pagination}</Box>
            </Box>
        </>
    )
}


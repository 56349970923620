import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'
import { ItemDetails } from 'components'
import ApplicationOwner from '../../DynamicComponents/ApplicationOwner'
import DummyPasswordBox from 'components/DynamicComponents/DummyPasswordBox'
import moment from 'moment'
import MasterPasswordLock from 'components/MasterPasswordLock'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
    fontSize: '14px',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ permission }) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('ConsentType')}
                    value={permission.consentType}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('ConsentDisplayName')}
                    value={permission.consentDisplayName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('ConsentDescription')}
                    value={permission.consentDescription}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('GlobalRightName')}
                    value={permission.globalRightFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('RightType')}
                    value={permission.rightType}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { EidButton } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& g': {
                stroke: 'white',
            },
        },
    }),
)

const EditCredential = ({ item }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const handleEdit = () => {}

    return (
        <>
            <Box width="120px">
                <EidButton.DrawerActionButton
                    fontColor="#ffffff"
                    bgColor="#307fc1"
                    width="100%"
                    onClick={handleEdit}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        height={'18px'}
                        width={'18px'}
                    >
                        <Icon
                            name="EditFile"
                            className={classes.root}
                            fill={'white'}
                            stroke={'white'}
                        />
                    </Box>
                    <Box display="flex" alignItems="center" marginLeft="10px">
                        <Typography style={{ color: '#ffffff' }}>
                            {t('Edit')}{' '}
                        </Typography>
                    </Box>
                </EidButton.DrawerActionButton>
            </Box>
        </>
    )
}

export default EditCredential

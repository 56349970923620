import PropTypes from 'prop-types'
import { CardContent, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Attribute from './Attribute'
import { TextFormatter } from 'components'
import DynamicComponents from 'components/DynamicComponents'
import { useUrlGenerator } from 'packages/core'
import { useAllApplications } from 'hooks'
import { isNilOrEmpty } from 'packages/core'

const useCardContentStyles = makeStyles({
    cardContent: (props) => ({
        padding: '5px 20px !important',
        paddingLeft: props.mobile && '20px',
    }),

    divider: {
        backgroundColor: '#e8e8e8',
        margin: '8px 0px',
        height: '0.5px',
    },

    image: {
        width: '100px',
    },
})

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const Attributes = ({ item, mobile, attributes }) => {
    const { t } = useTranslation()

    const contentClasses = useCardContentStyles({ mobile })
    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)

    const renderComponent = (a, item) => {
        const DynamicComponentToRender =
            DynamicComponents[a.component.componentName]

        return <DynamicComponentToRender attribute={a} data={{item}} />
    }
    const renderText = (a, item) => {
        if (a.redirectToOtherMS) {
            const urlDetails = a.redirectToOtherMS
            let url = ''
            if (urlDetails['microservice'] === 'myTasks') {
                const params = []
                urlDetails['queryParams'].forEach((p) => {
                    params.push(`${p.key}=${item[p.value]}`)
                })
                let queryString = params.join('&')
                url = getMyTasksUrl(`${urlDetails.path}`)
                if (!isNilOrEmpty(queryString)) {
                    url = getMyTasksUrl(`${urlDetails.path}?${queryString}`)
                }
            }
            return item[a.name] ? (
                <Link
                    style={{
                        fontSize: '14px',
                    }}
                    href={url}
                    target="_blank"
                >
                    {' '}
                    {item[a.name]}{' '}
                </Link>
            ) : (
                '-'
            )
        }
        return (
            <TextFormatter
                value={item[a.name] ? item[a.name] : '-'}
                tooltipProps={tooltipProps}
                style={{ fontSize: '14px' }}
            />
        )
    }
    return (
        <CardContent className={contentClasses.cardContent}>
            {attributes.map((a) => {
                const value = (
                    <>
                        {a.component?.componentName
                            ? renderComponent(a, item)
                            : renderText(a, item)}
                    </>
                )
                return (
                    <Attribute
                        key={a.name}
                        label={t(a.label)}
                        value={value}
                        orientation="vertical"
                        valueProps={{
                            display: 'flex !important',
                            alignItems: 'center',

                            ...a.cardStyles,
                        }}
                        textFormatterStyle={{
                            ...a.textFormatterStyle,
                        }}
                        labelProps={{ fontWeight: 'bold !important' }}
                        className={a.className}
                    />
                )
            })}
        </CardContent>
    )
}

Attributes.propTypes = {
    item: PropTypes.object,
    assignmentDetails: PropTypes.object,
    className: PropTypes.string,
}

export default Attributes

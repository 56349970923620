import PropTypes from 'prop-types'
import { CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Attribute from './Attribute'
import { TextFormatter } from 'components'
import DynamicComponents from 'components/DynamicComponents'

const useCardContentStyles = makeStyles({
    cardContent: (props) => ({
        padding: '0 20px 0px 20px',
        paddingLeft: props.mobile && '20px',
        marginTop: '20px',
    }),

    divider: {
        backgroundColor: '#e8e8e8',
        margin: '8px 0px',
        height: '0.5px',
    },

    image: {
        width: '100px',
    },
})

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const Attributes = ({ item, mobile, attributes }) => {
    const { t } = useTranslation()

    const contentClasses = useCardContentStyles({ mobile })

    const renderComponent = (a, item) => {
        const DynamicComponentToRender =
            DynamicComponents[a.component.componentName]

        return (
            <DynamicComponentToRender
                attribute={a}
                data={{ item }}
                imageClass={contentClasses.image}
            />
        )
    }

    const renderText = (a, item) => {
        return a.name === 'friendlyName' ? (
            <h1 style={{ margin: '0px', color: '#307fc1' }}>{item[a.name]}</h1>
        ) : (
            <TextFormatter
                value={item[a.name]}
                tooltipProps={tooltipProps}
                style={{ fontSize: '14px' }}
            />
        )
    }
    return (
        <CardContent className={contentClasses.cardContent}>
            {attributes
                .filter((a) => a.component?.componentName !== 'actionButtons')
                .map((a) => {
                    const value = (
                        <>
                            {a.component?.componentName
                                ? renderComponent(a, item)
                                : renderText(a, item)}
                        </>
                    )
                    return (
                        <Attribute
                            key={a.name}
                            label={a.name === 'friendlyName' ? '' : t(a.label)}
                            value={value}
                            orientation="horizontal"
                            valueProps={{
                                display: 'flex !important',
                                alignItems: 'center',
                                ...a.cardStyles,
                            }}
                            labelProps={{ fontWeight: 'bold !important' }}
                            className={a.className}
                        />
                    )
                })}
        </CardContent>
    )
}

Attributes.propTypes = {
    item: PropTypes.object,
    assignmentDetails: PropTypes.object,
    className: PropTypes.string,
}

export default Attributes

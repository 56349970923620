import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Avatar, Divider, Tooltip } from 'packages/eid-ui'

const styles = {
    name: {
        fontSize: '14px',
        color: '#5d6870',
        fontWeight: 'bold',
    },
    position: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        fontWeight: 'bold',
    },
    status: {
        color: '#01ae8f',
        fontSize: '14px',
    },
    divider: {
        margin: '0px 10px',
        color: '#d8d8dd',
        width: '16px',
        height: '1px',
    },
}

const StatusLine = ({ showTooltip, value, statusStyles }) => {
    if (!showTooltip) {
        return (
            <Typography noWrap style={{ ...styles.status, ...statusStyles }}>
                {value}
            </Typography>
        )
    } else {
        return (
            <Tooltip
                title={value ? value : ''}
                placement="bottom-start"
                enterDelay={500}
                enterNextDelay={500}
            >
                <Typography
                    noWrap
                    style={{ ...styles.status, ...statusStyles }}
                >
                    {value}
                </Typography>
            </Tooltip>
        )
    }
}
const avatarStyles = {
    small: {
        width: '18px',
    },
    medium: {
        width: '24px',
    },
    large: {
        width: '40px',
    },
}

const PersonDetails = (props) => {
    const {
        imgSrc,
        name,
        position,
        status,
        size = 'large',
        statusStyles,
        showTooltip,
    } = props

    return (
        <Box position="relative">
            <Box marginY="7.5px" display="flex" alignItems="center">
                <Box minWidth={size}
                    maxWidth={avatarStyles[size].width}
                    marginRight="10px">
                    <Avatar size={size} src={imgSrc} />
                </Box>
                <Box width={`calc(100% - ${avatarStyles[size].width})`}>
                    <Typography style={styles.name}>{name}</Typography>
                    <Box display="flex" alignItems="center" width="100%">
                        <StatusLine
                            showTooltip={showTooltip}
                            value={status}
                            statusStyles={statusStyles}
                        />

                        {position && (
                            <Divider vertical style={styles.divider} />
                        )}

                        <Typography style={styles.position}>
                            {position}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default PersonDetails

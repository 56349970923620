import { Box } from '@material-ui/core'
import { useAppState } from 'appContext'
import { Card } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import GenericFilter from '../EidFilter/GenericFilter'

const LocationFilter = ({url}) => {
    const { t } = useTranslation()
    const [state] = usePageContext()

    return (
        <Card
            cardTitle={t('Location')}
            collapsible
            expanded={Boolean(state.location)}
        >
            <Box
                padding="0"
                style={{
                    borderTop: 'solid 1px #efeff1',
                    backgroundColor: '#fbfbfd',
                }}
            >
                <Box padding="20px 20px 0px 20px">
                    <GenericFilter
                        type='tree'
                        searchable = 'true'
                        searchPlaceholder={t('Search')}
                        url='/api/Lookups/managementRoles/Locations'
                        dedupingInterval='1800000'
                        contextProp='location'
                    />
                </Box>
            </Box>
        </Card>
    )

}

export default LocationFilter

import { EidButton } from 'packages/eid-ui'
import { Typography, Box } from '@material-ui/core'

const styles = {
    normal: {
        width: '100%',
        height: '100%',
        backgroundColor: '#307fc1',
        color: '#fff !important',
        textDecoration: 'none',
        border: 'solid 1px #307fc1',
        '&:hover': {
            backgroundColor: '#307fc1',
        },
    },
    disabled: {
        backgroundColor: '#c5cbda',
        border: 'solid 1px #c5cbda',
        color: '#ffffff !important',
        opacity: '0.8',
    },

    label: {
        fontSize: '11px',
        lineHeight: 'normal',
    },
}

const RequestAccessButton = ({ label, redirectURL, parentStyle = {}, ...rest }) => {
    return (
        <EidButton
            style={{ ...styles.normal, ...parentStyle }}
            disabledStyles={styles.disabled}
            {...rest}
        >
            <a href={redirectURL} target="_blank" style={{textDecoration : 'none', color: 'inherit'}}>
                <Typography style={styles.label}>{label}</Typography>
            </a>
        </EidButton>
    )
}

export default RequestAccessButton

import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { Fragment } from 'react'
import { Box, Typography } from '@material-ui/core'

const GroupChangeType = ({ data, attribute }) => {
    const { t } = useTranslation()
    const { item } = data

    const renderComponent = () => {
        if (item && item[attribute.name] === null) {
            return '-'
        } else if (item && item[attribute.name] === 'Removed') {
            return (
                <Box style={{ display: 'flex' }}>
                    <Icon
                        color="#d0021b"
                        style={{ width: '20px', height: '20px' }}
                        name="UncheckedRound"
                    />

                    <Typography
                        style={{
                            fontSize: '13px',
                            color: '#ba002c',
                            fontWeight: 'bold',
                            marginLeft: '8px',
                        }}
                    >
                        {item[attribute.name]}
                    </Typography>
                </Box>
            )
        } else if (item && item[attribute.name] === 'Added') {
            return (
                <Box style={{ display: 'flex' }}>
                    <Icon
                        color="#029435"
                        style={{ width: '20px', height: '20px' }}
                        name="FilledCheck"
                    />
                    <Typography
                        style={{
                            fontSize: '13px',
                            color: '#3eaa05',
                            fontWeight: 'bold',
                            marginLeft: '8px',
                        }}
                    >
                        {item[attribute.name]}
                    </Typography>
                </Box>
            )
        } else if (item && item[attribute.name] === 'Inventory') {
            return (
                <Box style={{ display: 'flex' }}>
                    <Icon
                        color="#029435"
                        style={{ width: '20px', height: '20px' }}
                        name="Inventory"
                    />
                    <Typography
                        style={{
                            fontSize: '13px',
                            color: '#453fbb',
                            fontWeight: 'bold',
                            marginLeft: '8px',
                        }}
                    >
                        {item[attribute.name]}
                    </Typography>
                </Box>
            )
        }
    }
    return <Fragment>{renderComponent()}</Fragment>
}
export default GroupChangeType

import { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Paper, styled, Tab } from '@material-ui/core'
import { useApplication } from 'hooks'
import { getFullImageUrl } from 'utils'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { Overview } from './Overview/Overview'
import ApplicationCertificates from 'components/ApplicationBanner/Application Certificates/ApplicationCertificates'
import { LeftPaneTabs, LeftPaneActions } from 'components'
import ApplicationSecrets from './Application Secrets/ApplicationSecrets'
import SecretsDrawer from './Application Secrets/SecretsDrawer'
import { useHistory } from 'react-router'
import CertificatesDrawer from './Application Certificates/CertificatesDrawer'
import { SecretsContextProvider } from 'secretsContext'
import { CertificatesContextProvider } from 'certificatesContext'
import { ScopesContextProvider } from 'scopesContext'
import { AppRolesContextProvider } from 'appRolesContext'
import ApplicationScopes from './Application Scopes/ApplicationScopes'
import ScopesDrawer from './Application Scopes/ScopesDrawer'
import RolesDrawer from './Azure Application Roles/RolesDrawer'
import AzureApplicationRoles from './Azure Application Roles/AzureApplicationRoles'
import { PermissionsContextProvider } from 'permissionsContext'
import ApplicationPermissions from './API Permissions/Permissions'
import PermissionsDrawer from './API Permissions/PermissionsDrawer'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
            width: '100%',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        imgContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            width: '100%',
            minHeight: '125px',
            borderRadius: '8px',
            marginBottom: '20px',

            '& img': {
                maxHeight: '80px',
                maxWidth: '80px',
            },

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                minWidth: '116px',
                '& img': {
                    maxHeight: '33px',
                    width: '66px',
                },
            },
        },
        nameContainer: {
            width: '100%',
            minHeight: '50px',
            marginBottom: '20px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            fontSize: '22px',
            fontWeight: 'bold',
            borderRadius: '8px',
            color: '#3b454d',
        },

        tabsContainer: {
            width: '100%',
            borderRadius: '0px',
            marginBottom: '20px',
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        description: {
            [`@media (min-width:1366px)`]: {
                maxWidth: '300px',
            },
        },
        actionsContainer: {
            minWidth: '230px',
            paddingRight: theme.spacing(2),

            [`@media (max-width:445px)`]: {
                alignItems: 'flex-start',
            },
        },
        backButton: {
            '& >button': {
                '&:hover': {
                    backgroundColor: '#307fc1',
                    color: 'white',
                    '& span': {
                        color: theme.palette.common.white,
                    },
                },
            },
        },
        moreInfoButton: {
            marginTop: theme.spacing(3),
            '& >button': {
                backgroundColor: theme.palette.grey[900],
                color: theme.palette.common.white,

                border: `solid 1px ${theme.palette.grey[900]}`,

                '&:hover': {
                    backgroundColor: theme.palette.grey[900],

                    '& span': {
                        //  color: theme.palette.common.white,
                    },
                },
            },
        },
        actionDropdown: {
            marginTop: '40px',
        },
        showMoreButton: {
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },

            position: 'absolute',
            bottom: '-15px',
            left: 'calc(50% - 83px)',
            zIndex: 2,
            '& > button': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                border: 'none',
                height: '32px',
                minWidth: '115px',
                color: '#307fc1',
                fontSize: '11px',
                textTransform: 'uppercase',
                borderRadius: '15px',
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                cursor: 'pointer',
                paddingLeft: '12px',
                paddingRight: '6px',
                '& svg': {
                    color: '#307fc1',
                    height: '20px',
                },
            },
        },
        collapsibleContainer: {
            display: 'flex',
            flexDirection: 'column',
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
            '& div': {
                textAlign: 'start',
            },
        },
        actionContainerMobile: {},
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: '#307fc1',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
        commonDeputies: {
            'flex-wrap': 'nowrap',
        },
    }),
)

export const TabsColumn = styled(Box)({
    display: 'flex',
    minWidth: '340px',
    flexDirection: 'column',
    margin: '0px 12px 0px 8px',
    marginRight: '20px',
    width: '30%',
    flex: '36%',
})

export const ApplicationBanner = (props: any) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { applicationId } = props
    const { data, isLoading } = useApplication(applicationId)
    const [activeTab, setActiveTab] = useState('overview')
    const query = useQuery()
    const secretId = query.get('secretId')
    const certificateId = query.get('certificateId')
    const scopeId = query.get('scopeId')
    const appRoleId = query.get('appRoleId')
    const permissionId = query.get('permissionId')
    const history = useHistory()
    const handleTabChange = (tab: any) => {
        setActiveTab(tab)
    }

    const allTabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
        },
        {
            label: t('ClientSecrets'),
            value: 'secrets',
            icon: <Icon name="ClientSecret" />,
            display: data?.isAzureApplication,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-ClientSecrets',
            },
        },
        {
            label: t('ClientCertificates'),
            value: 'certificates',
            icon: <Icon name="ClientCertificate" />,
            display: data?.isAzureApplication,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-ClientCertificates',
            },
        },
        {
            label: t('Scopes'),
            value: 'scopes',
            icon: <Icon name="Scope" />,
            display: data?.isAzureApplication,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-Scopes',
            },
        },
        {
            label: t('AzureApplicationRoles'),
            value: 'roles',
            icon: <Icon name="AppRole" />,
            display: data?.isAzureApplication,
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-AzureApplicationRoles',
            },
        },
        {
            label: t('ApiPermissions'),
            value: 'permissions',
            icon: <Icon name="ApiPermission" />,
            display: data?.isAzureApplication,
            requireAccess: {
                control: 'ResourceAdmin-Application-Details-APIPermissions',
            },
        },
    ]

    return (
        <>
            <Box display="flex">
                <TabsColumn>
                    <Paper className={classes.nameContainer}>
                        {data?.friendlyName}
                    </Paper>
                    <Paper className={classes.imgContainer}>
                        <img
                            alt={data?.friendlyName}
                            src={
                                isNilOrEmpty(data?.imageUrl)
                                    ? getFullImageUrl(
                                          '/webcdn/Images/AppLogos/Genric-1.png',
                                      )
                                    : getFullImageUrl(
                                          data?.applicationLogoImageUrl,
                                      )
                            }
                        />
                    </Paper>
                    <Paper className={classes.tabsContainer}>
                        <LeftPaneTabs
                            active={activeTab}
                            onChange={handleTabChange}
                            tabs={allTabs}
                        />
                    </Paper>
                    <LeftPaneActions
                        resource={data}
                        resourceType="applications"
                        activeTab={activeTab}
                    />
                </TabsColumn>
                <Box className={classes.root}>
                    {activeTab === 'overview' && (
                        <Paper style={{ paddingRight: 10 }}>
                            <Overview applicationId={applicationId} />
                        </Paper>
                    )}
                    {activeTab === 'secrets' && (
                        <SecretsContextProvider key="RequestViewSecrets">
                            <ApplicationSecrets
                                azureApplicationId={data?.azureApplicationId}
                            />
                        </SecretsContextProvider>
                    )}
                    {activeTab === 'certificates' && (
                        <CertificatesContextProvider key="RequestViewCertificates">
                            <ApplicationCertificates
                                azureApplicationId={data?.azureApplicationId}
                            />
                        </CertificatesContextProvider>
                    )}
                    {activeTab === 'scopes' && (
                        <ScopesContextProvider key="RequestViewScopes">
                            <ApplicationScopes id={data?.id} />
                        </ScopesContextProvider>
                    )}
                    {activeTab === 'roles' && (
                        <AppRolesContextProvider key="RequestViewAppRoles">
                            <AzureApplicationRoles id={data?.id} />
                        </AppRolesContextProvider>
                    )}
                    {activeTab === 'permissions' && (
                        <PermissionsContextProvider key="RequestViewPermissions">
                            <ApplicationPermissions
                                id={data?.azureApplicationId}
                            />
                        </PermissionsContextProvider>
                    )}
                </Box>
            </Box>
            {secretId && (
                <SecretsContextProvider key="RequestViewSecrets">
                    <SecretsDrawer
                        id={secretId}
                        onClose={() => {
                            query.delete('secretId')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </SecretsContextProvider>
            )}
            {certificateId && (
                <CertificatesContextProvider key="RequestViewCertificates">
                    <CertificatesDrawer
                        id={certificateId}
                        onClose={() => {
                            query.delete('certificateId')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </CertificatesContextProvider>
            )}
            {scopeId && (
                <ScopesContextProvider key="RequestViewScopes">
                    <ScopesDrawer
                        id={scopeId}
                        onClose={() => {
                            query.delete('scopeId')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </ScopesContextProvider>
            )}
            {appRoleId && (
                <AppRolesContextProvider key="RequestViewAppRoles">
                    <RolesDrawer
                        id={appRoleId}
                        onClose={() => {
                            query.delete('appRoleId')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </AppRolesContextProvider>
            )}
            {permissionId && (
                <PermissionsContextProvider key="RequestViewPermissions">
                    <PermissionsDrawer
                        id={permissionId}
                        onClose={() => {
                            query.delete('permissionId')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PermissionsContextProvider>
            )}
        </>
    )
}

import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'
import * as R from 'ramda'

export const APPLICATION_SETTINGS_KEY = 'APPLICATION_SETTINGS'
export const ACCESS_SUBCOMPONENTS_KEY = 'ACCESS_SUBCOMPONENTS'
export const ALL_APPLICATIONS_KEY = 'ALL_APPLICATIONS'
export const REQUEST_POLICIES_KEY = 'REQUEST_POLICIES'


export const useApplicationSettings = () => {
    const callApi = useAxios()
    return useQuery(
        APPLICATION_SETTINGS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/configuration/settings',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useAccessSubcomponents = () => {
    const callApi = useAxios()
    return useQuery(
        ACCESS_SUBCOMPONENTS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/configuration/access/subcomponents',
            }),
        {
            staleTime: Infinity,
        },
    )
}

export const useAllApplications = () => {
    const callApi = useAxios()
    return useQuery(
        ALL_APPLICATIONS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/configuration/access/microservices',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

interface IAccessRequestPolicy {
    id: string
    name: string
    friendlyName: string
    description: string
    isRequestAllowed: boolean
    isTimeConstrained: boolean
    isEndDateSelectable: boolean
    defaultValueInMinutes?: number
    maximumValueInMinutes?: number
}


const _reducePolicies: (
    policies: IAccessRequestPolicy[],
) => Record<string, IAccessRequestPolicy> = (policies) => {
    return R.reduce<IAccessRequestPolicy, Record<string, IAccessRequestPolicy>>(
        (result, policy) => {
            result[policy.id] = policy
            return result
        },
        {},
        policies,
    )
}

export const useAccessRequestPolicies = () => {
    const callApi = useAxios()
    return useQuery<Record<string, IAccessRequestPolicy>>(
        REQUEST_POLICIES_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/configuration/requestPolicies?take=50',
            }).then((data) => _reducePolicies(data.data)),
        {
            staleTime: Infinity,
        },
    )
}

export const useAccessRequestPolicy = (
    policyId: string,
) => {
    const { data: requestAccessPolicies } = useAccessRequestPolicies()

    if (requestAccessPolicies === undefined) return undefined

    const policy: IAccessRequestPolicy = requestAccessPolicies[policyId]

    return policy
}


import { Box } from '@material-ui/core'
import { useAppState } from 'appContext'
import { Card } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import GenericFilter from '../EidFilter/GenericFilter'

const GlobalRightsFilter = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()
    const [appState] = useAppState()

    return (
        <Card
            cardTitle={t('Rights')}
            collapsible
            expanded={Boolean(state.rights)}
        >
            <Box
                padding="0"
                style={{
                    borderTop: 'solid 1px #efeff1',
                    backgroundColor: '#fbfbfd',
                }}
            >
                <Box padding="20px 20px 0px 20px">
                    <GenericFilter
                        type='autocomplete'
                        placeholder={t('GlobalRights')}
                        optionConfig={{
                            type: 'labelWithSubLabel',
                            labelProp: 'friendlyName',
                            subLabelProp: 'description',
                        }}
                        url='/api/Lookups/globalRights'
                        queryParams={{
                            take: 25
                        }}
                        contextProp='rights'
                        debounceInterval='500'
                        dedupingInterval='60000'
                        state={state}
                        appState={appState}
                    />
                </Box>
            </Box>
        </Card>
    )

}

export default GlobalRightsFilter

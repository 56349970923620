import { Trans, useTranslation } from 'react-i18next'
import TableGrid from 'components/TableGrid'
import { Box } from '@material-ui/core'

export const LocalRights = ({ isLoading, isLoadingMore, data, pagination }) => {
    const { t } = useTranslation()
    const attributes = [
        {
            name: 'name',
            label: t('FriendlyName'),
            style: {
                width: '296px',
            },
        },
        {
            name: 'description',
            label: t('Description'),
            style: {
                width: '290px',
            },
        },
        {
            name: 'rightTypeName',
            label: t('RightType'),
            style: {
                width: '160px',
            },
        },
        {
            name: 'resourceSystemName',
            label: t('ResourceSystem'),
            style: {
                width: '160px',
            },
        },
    ]
    return (
        <>
            <TableGrid
                loading={isLoading}
                data={data}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
                fallbackMessage={<Trans i18nKey="NoRightsFound" />}
            />
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '36px',
                }}
            >
                <span style={{ background: '#fff', borderRadius: '5px' }}>
                    {pagination}
                </span>
            </Box>
        </>
    )
}

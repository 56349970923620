import React, { Fragment, useEffect } from 'react'
import ApplicationsLayout from './ApplicationsLayout'
import { useAuthState } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { usePageContext } from 'pageContext'

const allFilters = [
    {
        name: 'applicationsOwnedByFilter',
        requireAccess: 'ResourceAdmin-OwnedApplications-Control',
    },
    {
        name: 'showOnlyAzureApplications',
        requireAccess: 'ResourceAdmin-showOnlyAzureApplications-Control',
    },
    {
        name: 'targetSystemFilter',
        requireAccess: 'ResourceAdmin-ApplicationsTargetSystem-Control',
    },
    {
        name: 'applicationsAdvancedSearch',
        requireAccess: 'ResourceAdmin-ApplicationsAdvancedSearch-Control',
    },
]

const RequestViewAllApplications = () => {
    const [state, dispatch] = usePageContext()
    const [{ currentUserId, friendlyName }] = useAuthState()

    const { trimControlsBasedOnAccess } = useSubcomponents()

    const filters = trimControlsBasedOnAccess(allFilters).map((f) => f.name)
    const hasOwnedByFilterAccess =
        filters.indexOf('applicationsOwnedByFilter') >= 0
    useEffect(() => {
        if (!hasOwnedByFilterAccess) {
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'ownedBy',
                    value: {
                        id: currentUserId,
                        friendlyName: friendlyName,
                    },
                },
            })
        }
    }, [currentUserId])

    return (
        <Fragment>
            <ApplicationsLayout filters={filters} />
        </Fragment>
    )
}

export { RequestViewAllApplications }

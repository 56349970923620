import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'
import { useApplicationScopes } from 'hooks'
import { useEidGrid, CardViewLoader } from 'components'
import { ScopeRow, ScopesTable, ScopesTableHead } from './ScopesTableComponent'
import scopesAttributes from './scopesAttributes'
import ScopeCard from './ScopesCard'

const ApplicationScopes = ({ id }) => {
    const { t } = useTranslation()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationScopes(id)

    const isSmallScreen = useIsSmallScreen()

    const defaultView = isSmallScreen ? 'card' : 'table'
    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoScopesMessage'),
        pagination,
        attributes: scopesAttributes,
        // cardViewConfig: { TODO: Enable after Mobile UX is done
        //     LoadingIndicatorComponent: CardViewLoader,
        //     CardComponent: ScopeCard,
        // },
        tableViewConfig: {
            TableComponent: ScopesTable,
            TableHeadComponent: ScopesTableHead,
            TableRowComponent: ScopeRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    return <>{viewToRender}</>
}

export default ApplicationScopes

import React, { useReducer, createContext, useContext } from 'react'
import reducer, { initialState } from './reducer'

export const AppRolesContext = createContext({})

export const AppRolesContextProvider = ({ children }) => (
    <AppRolesContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </AppRolesContext.Provider>
)

export const useAppRolesContext = () => useContext(AppRolesContext)

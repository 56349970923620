export const initialState = {
    searchTerm: '',
    tags: null,
    page: 1,
    startDate: null,
    endDate: null,
    sortOrder: null,
    sortBy: null,
    sortLabel: null,
    ownedBy: null,
    accountStore: null,
    accountStoreUsageType: null,
    shouldLoadTags: null,
    location: null,
    showOnlyAzureApplications: false,
    workflowsCount: 0,
    groupType: null,
    highLevelClassification: null,
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_PROP':
            return {
                ...state,
                page: 1,
                [payload.key]: payload.value,
            }
        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                page: 1,
                ...payload,
            }
        case 'SET_TAGS':
            return {
                ...state,
                tags: payload,
            }
        case 'SET_PAGE':
            return {
                ...state,
                page: payload,
            }

        default:
            return state
    }
}

export default reducer

import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'
import { ItemDetails } from 'components'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
    fontSize: '14px',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ role }) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('AllowedMemberTypes')}
                    value={role.rightAllowedAssigeeTypeFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Value')}
                    value={role.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Description')}
                    value={role.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

import React, { useReducer, createContext, useContext } from 'react'
import reducer, { initialState } from './reducer'

export const PermissionsContext = createContext({})

export const PermissionsContextProvider = ({ children }) => (
    <PermissionsContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </PermissionsContext.Provider>
)

export const usePermissionsContext = () => useContext(PermissionsContext)

import { Fragment } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { Owners } from '../../../../components/Owners/Owners'
import {
    useAppRole,
    useAccessRequestPolicy,
    useOwnersAndApprovers,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { PeopleListCard } from 'components/PeopleListCard/PeopleListCard'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: '#307fc1',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
    }),
)

export const Overview = (props) => {
    const classes = useStyles()

    const { basicAttributes, appRoleId } = props
    const { isLoading: detailsLoading, data: appRole } = useAppRole(appRoleId)
    const policy = useAccessRequestPolicy(appRole && appRole.requestPolicyId)
    const { data: ownersAndApprovers, isLoading: loading } =
        useOwnersAndApprovers(appRoleId)
    const { t } = useTranslation()

    const appRoleDetails = (
        <Fragment>
            <Box className={classes.basicInfo}>
                {basicAttributes &&
                    basicAttributes.map((att, i) => {
                        return (
                            <Attribute key={att.value + i}>
                                <AttributeLabel>{t(att.label)}</AttributeLabel>
                                <AttributeValue>
                                    {appRole && appRole[att.value]}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
            </Box>
        </Fragment>
    )
    const policyDetails = (
        <Box>
            <Attribute>
                <AttributeLabel>{t('AccessRequestPolicy')}</AttributeLabel>
                <AttributeValue>{policy && policy.name}</AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>
                    {t('AllowsTimeConstrainedAccess')}
                </AttributeLabel>
                <AttributeValue>
                    {policy && policy.isTimeConstrained ? t('Yes') : t('No')}
                </AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>{t('DefaultAccessDuration')}</AttributeLabel>
                <AttributeValue>
                    {policy && policy.defaultValueInMinutes}
                </AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>{t('MaxAccessDuration')}</AttributeLabel>
                <AttributeValue>
                    {policy && policy.maximumValueInMinutes}
                </AttributeValue>
            </Attribute>
        </Box>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                <Box className={classes.section} style={{ width: '85%' }}>
                    {appRoleDetails}
                </Box>

                <Box className={classes.section}>{policyDetails}</Box>
                <Box className={classes.section}>
                    <Attribute>
                        <AttributeLabel> {t('Owners')}</AttributeLabel>
                        <PeopleListCard
                            data={ownersAndApprovers?.owners}
                            isLoading={loading}
                        />
                    </Attribute>
                </Box>
                <Box
                    className={classes.section}
                    style={{ justifyContent: 'end' }}
                >
                    <Attribute>
                        <AttributeLabel> {t('Approvers')}</AttributeLabel>
                        <PeopleListCard
                            data={ownersAndApprovers?.approvers}
                            isLoading={loading}
                        />
                    </Attribute>
                </Box>
            </Box>
        </Fragment>
    )

    return (
        <>
            {detailsLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.section}>{renderContent()}</Box>
                </Fragment>
            )}
        </>
    )
}

import { useState, useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Paper, styled } from '@material-ui/core'
import {
    useApplicationLocalRights,
    useApplicationSensitiveFunctions,
    useManagementRole,
} from 'hooks'
import { Overview } from './Overview/Overview'
import { ItemDetails, LeftPaneActions, LeftPaneTabs } from 'components'
import { useTranslation } from 'react-i18next'
import { PeopleAsMembers } from './PeopleAsMembers'
import { AllMembers } from './AllMembers'
import { useDebounce } from 'packages/core'
import { LocalRights } from './Overview/LocalRights'
import { LocalSensitiveFunctions } from './Overview/LocalSensitiveFunctions'
import Search from 'components/Filters/Search'
import useSubcomponents from 'useSubcomponents'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
            width: '100%',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        imgContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            width: '100%',
            minHeight: '125px',
            borderRadius: '8px',
            marginBottom: '20px',
            '& img': {
                maxHeight: '80px',
                maxWidth: '80px',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                minWidth: '116px',
                '& img': {
                    maxHeight: '33px',
                    width: '66px',
                },
            },
        },
        nameContainer: {
            width: '100%',
            minHeight: '50px',
            marginBottom: '20px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            fontSize: '22px',
            fontWeight: 'bold',
            borderRadius: '8px',
            color: '#3b454d',
        },
        tabsContainer: {
            width: '100%',
            borderRadius: '0px',
            marginBottom: '20px',
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },
        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        description: {
            [`@media (min-width:1366px)`]: {
                maxWidth: '300px',
            },
        },
        actionsContainer: {
            minWidth: '230px',
            paddingRight: theme.spacing(2),

            [`@media (max-width:445px)`]: {
                alignItems: 'flex-start',
            },
        },
        backButton: {
            '& >button': {
                '&:hover': {
                    backgroundColor: '#307fc1',
                    color: 'white',
                    '& span': {
                        color: theme.palette.common.white,
                    },
                },
            },
        },
        moreInfoButton: {
            marginTop: theme.spacing(3),
            '& >button': {
                backgroundColor: theme.palette.grey[900],
                color: theme.palette.common.white,

                border: `solid 1px ${theme.palette.grey[900]}`,

                '&:hover': {
                    backgroundColor: theme.palette.grey[900],

                    '& span': {},
                },
            },
        },
        actionDropdown: {
            marginTop: '40px',
        },
        showMoreButton: {
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
            position: 'absolute',
            bottom: '-15px',
            left: 'calc(50% - 83px)',
            zIndex: 2,
            '& > button': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                border: 'none',
                height: '32px',
                minWidth: '115px',
                color: '#307fc1',
                fontSize: '11px',
                textTransform: 'uppercase',
                borderRadius: '15px',
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                cursor: 'pointer',
                paddingLeft: '12px',
                paddingRight: '6px',
                '& svg': {
                    color: '#307fc1',
                    height: '20px',
                },
            },
        },
        collapsibleContainer: {
            display: 'flex',
            flexDirection: 'column',
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
            '& div': {
                textAlign: 'start',
            },
        },
        actionContainerMobile: {},
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: '#307fc1',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
        commonDeputies: {
            'flex-wrap': 'nowrap',
        },
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
    }),
)
const subTabs = [
    {
        name: 'Access Granted',
        value: 'accessGranted',
        label: 'AccessGranted',
        display: false,
    },
    {
        name: 'Sensitive Functions',
        value: 'localSensitiveFunctions',
        label: 'SensitiveFunctions',
        display: true,
    },
    {
        name: 'Rights',
        value: 'rights',
        label: 'Rights',
        display: true,
    },

    {
        name: 'Applications',
        value: 'applications',
        label: 'Applications',
        display: false,
    },
]
const subTabsRequiredAccess = [
    {
        name: 'localSensitiveFunctions',
        requireAccess:
            'ResourceAdmin-ManagementRole-Details-Overview-SensitiveFunctions',
    },
    {
        name: 'rights',
        requireAccess: 'ResourceAdmin-ManagementRole-Details-Overview-Rights',
    },
]
export const TabsColumn = styled(Box)({
    display: 'flex',
    minWidth: '340px',
    flexDirection: 'column',
    margin: '0px 12px 0px 8px',
    marginRight: '20px',
    width: '30%',
    flex: '36%',
})

export const ManagementRoleDetails = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { tabs, basicAttributes, managementRoleId } = props
    const { isLoading: detailsLoading, data: managementRole } =
        useManagementRole(managementRoleId)

    const [activeTab, setActiveTab] = useState('overview')
    const { trimControlsBasedOnAccess } = useSubcomponents()

    const subTabsAccess = trimControlsBasedOnAccess(subTabsRequiredAccess).map(
        (f) => f.name,
    )
    const [subActiveTab, setSubActiveTab] = useState(
        subTabsAccess && subTabsAccess.length > -1 ? subTabsAccess[0] : null,
    )
    const [callApi, setCallApi] = useState(true)
    const [searchKey, setSearchKey] = useState({
        rights: '',
        localSensitiveFunctions: '',
        accessGranted: '',
        applications: '',
    })
    const [query, setQuery] = useState('')

    const handleTabChange = (tab) => {
        setActiveTab(tab)
    }
    const debouncedSearchValue = useDebounce(searchKey[subActiveTab])

    let queryData = {
        [subActiveTab]: debouncedSearchValue,
    }
    const handleSearchChange = (keyword) => {
        setSearchKey({ [subActiveTab]: keyword })
    }
    const handleClear = () => {
        setSearchKey({ [subActiveTab]: '' })
    }

    const {
        list: sensitiveFunctions,
        totalCount: sensitiveFunctionsCount,
        loading: sensitiveFunctionsLoading,
        loadingMore: sensitiveFunctionsLoadingMore,
        pagination: sensitiveFunctionsPagination,
    } = useApplicationSensitiveFunctions(
        managementRoleId,
        subTabsAccess.indexOf('localSensitiveFunctions') > -1 &&
            Boolean(callApi || subActiveTab === 'localSensitiveFunctions'),
        queryData['localSensitiveFunctions'] ?? '',
    )
    const {
        list: localRights,
        totalCount: localRightsCount,
        loading: localRightsLoading,
        loadingMore: localRightsLoadingMore,
        pagination: localRightsPagination,
    } = useApplicationLocalRights(
        managementRoleId,
        subTabsAccess.indexOf('rights') > -1 &&
            Boolean(callApi || subActiveTab === 'rights'),
        queryData['rights'] ?? '',
    )
    useEffect(() => {
        if (callApi && sensitiveFunctions && localRights) {
            setCallApi(false)
        }
    }, [sensitiveFunctions, localRights])

    const getTabCount = (tab) => {
        switch (tab) {
            case 'rights': {
                return localRightsCount
            }
            case 'localSensitiveFunctions': {
                return sensitiveFunctionsCount
            }
            default: {
                return 0
            }
        }
    }
    return (
        <>
            <Box display="flex">
                <TabsColumn>
                    <Paper className={classes.nameContainer}>
                        {managementRole?.friendlyName}
                    </Paper>
                    <Paper className={classes.tabsContainer}>
                        <LeftPaneTabs
                            active={activeTab}
                            onChange={handleTabChange}
                            application={managementRole}
                            tabs={tabs}
                        />
                    </Paper>
                    <LeftPaneActions
                        resource={managementRole}
                        resourceType="managementRoles"
                        activeTab={activeTab}
                        verb={'ManagementRole'}
                    />
                </TabsColumn>
                <Box className={classes.root}>
                    {activeTab === 'overview' && (
                        <Box>
                            <Paper
                                style={{ padding: '32px', paddingBottom: 0 }}
                            >
                                <Overview
                                    managementRoleId={managementRoleId}
                                    detailsLoading={detailsLoading}
                                    basicAttributes={basicAttributes}
                                />
                                {subTabsAccess.length > 0 && (
                                    <Box
                                        style={{
                                            marginLeft: '-18px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <ItemDetails.Tabs
                                            value={subActiveTab}
                                            style={{ border: 'none' }}
                                            onChange={(_, value) => {
                                                setSearchKey({
                                                    [subActiveTab]: '',
                                                })
                                                setSubActiveTab(value)
                                            }}
                                        >
                                            {subTabs
                                                .filter(
                                                    (x) =>
                                                        x.display === true &&
                                                        subTabsAccess.indexOf(
                                                            x.value,
                                                        ) > -1,
                                                )
                                                .map((tab, index) => {
                                                    return (
                                                        <ItemDetails.Tabs.Tab
                                                            key={
                                                                tab.value +
                                                                index
                                                            }
                                                            value={tab.value}
                                                            label={
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        flexDirection:
                                                                            'column',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            marginTop: 5,
                                                                            marginRight: 5,
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            tab.label,
                                                                        )}{' '}
                                                                        <Box
                                                                            paddingX="7px"
                                                                            paddingY="10px"
                                                                            style={{
                                                                                color:
                                                                                    subActiveTab ===
                                                                                    tab.value
                                                                                        ? '#fff'
                                                                                        : '#8f8f8f',
                                                                                backgroundColor:
                                                                                    subActiveTab ===
                                                                                    tab.value
                                                                                        ? '#2e7aba'
                                                                                        : '#e7e7e7',
                                                                            }}
                                                                            className={
                                                                                classes.totalCountLabel
                                                                            }
                                                                        >
                                                                            {getTabCount(
                                                                                tab.value,
                                                                            )}
                                                                        </Box>
                                                                    </span>
                                                                </div>
                                                            }
                                                        />
                                                    )
                                                })}
                                        </ItemDetails.Tabs>
                                        <Box
                                            width="308px"
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Search
                                                handleSearch={
                                                    handleSearchChange
                                                }
                                                placeholder={t(
                                                    'SearchFilterMembers',
                                                )}
                                                value={
                                                    searchKey[subActiveTab]
                                                        ? searchKey[
                                                              subActiveTab
                                                          ]
                                                        : ''
                                                }
                                                handleClear={handleClear}
                                                outlined
                                            />
                                        </Box>
                                    </Box>
                                )}
                            </Paper>
                            {/* {subActiveTab === 'accessGranted' && (
                                <AccessGrantedTable></AccessGrantedTable>
                            )} */}
                            {subActiveTab === 'localSensitiveFunctions' && (
                                <LocalSensitiveFunctions
                                    isLoading={sensitiveFunctionsLoading}
                                    data={sensitiveFunctions}
                                    isLoadingMore={
                                        sensitiveFunctionsLoadingMore
                                    }
                                    pagination={sensitiveFunctionsPagination}
                                />
                            )}
                            {subActiveTab === 'rights' && (
                                <LocalRights
                                    isLoading={localRightsLoading}
                                    data={localRights}
                                    isLoadingMore={localRightsLoadingMore}
                                    pagination={localRightsPagination}
                                />
                            )}
                        </Box>
                    )}
                    {activeTab === 'peopleMembers' && (
                        <PeopleAsMembers managementRole={managementRole} />
                    )}
                    {activeTab === 'allMembers' && (
                        <AllMembers managementRole={managementRole} />
                    )}
                </Box>
            </Box>
        </>
    )
}

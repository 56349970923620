import React, { useState } from 'react'
import { Loader } from 'packages/eid-ui'
import {
    MenuItem,
    makeStyles,
    ListItemText,
    ListItemIcon,
    Paper,
    Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useUiActionsByResource } from 'hooks'
import { useTranslation } from 'react-i18next'
import config from 'config'

const useMenuStyles = makeStyles({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
        '&:hover': {
            backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
            borderLeft: '2px solid #307fc1',
            '& svg': {
                opacity: 1,
                filter: 'grayscale(0%) ',
                color: 'blue',
            },
        },
    },
    icon: {
        minWidth: '40px',
        color: 'black',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    noActionMessage: {
        padding: '6px 30px',
        minHeight: '40px',
        textAlign: 'center',
    },
})

const useHeaderStyles = makeStyles({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
        borderRadius: '4px',
    },
    icon: {
        minWidth: '40px',
        color: 'black',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    selected: {
        backgroundColor: '#307fc1 !important',
        color: '#fff',
    },
})

const ActionsList = (props) => {
    const { resource, resourceType, activeTab, verb } = props
    const classes = useMenuStyles()
    const { t } = useTranslation()

    const UI_ACTIONS_NOUN = 'ResourceAdmin'
    const tab = activeTab.charAt(0).toUpperCase() + activeTab.slice(1)

    const { data, isLoading } = useUiActionsByResource(
        resource.id,
        UI_ACTIONS_NOUN,
        true,
        verb,
    )
    const { data: actions, isLoading: actionsLoading } = useUiActionsByResource(
        resource.id,
        UI_ACTIONS_NOUN + tab,
        activeTab !== 'overview',
        verb,
    )
    const query = useQuery()
    const history = useHistory()

    const handleClick = (name) => {
        query.set('workflowName', name)
        query.set('resourceID', `${resource.resourceID}`)
        history.push(
            `${
                config.APP_SUBPATH
            }/${resourceType}/workflows?${query.toString()}`,
        )
    }

    return (
        <>
            <Paper style={{ borderRadius: '8px' }}>
                {isLoading || actionsLoading ? (
                    <Loader />
                ) : data && data.length === 0 && !(actions?.length > 0) ? (
                    <Typography className={classes.noActionMessage}>
                        {t('NoActionFound')}
                    </Typography>
                ) : (
                    data &&
                    data.map((d, index) => (
                        <MenuItem
                            key={d.id + index}
                            onClick={() => handleClick(d.requestWorkflowName)}
                            classes={classes}
                        >
                            <ListItemIcon className={classes.icon}>
                                <Icon name="Arrow" direction="right" />
                            </ListItemIcon>
                            <ListItemText>{d.friendlyName}</ListItemText>
                        </MenuItem>
                    ))
                )}
                {actions &&
                    actions.map((d, index) => (
                        <MenuItem
                            key={d.id + index}
                            onClick={() => handleClick(d.requestWorkflowName)}
                            classes={classes}
                        >
                            <ListItemIcon className={classes.icon}>
                                <Icon name="Arrow" direction="right" />
                            </ListItemIcon>
                            <ListItemText>{d.friendlyName}</ListItemText>
                        </MenuItem>
                    ))}
            </Paper>
        </>
    )
}

const LeftPaneActions = (props) => {
    const { resource, resourceType, activeTab, verb } = props
    const classes = useHeaderStyles()
    const [open, setOpen] = useState(false)
    const header = (
        <Paper style={{ marginBottom: '5px' }}>
            <MenuItem
                classes={classes}
                selected={open}
                onClick={() => {
                    setOpen((prev) => !prev)
                }}
            >
                <ListItemIcon className={classes.icon}>
                    <Icon name="Actions" color={open ? 'white' : 'black'} />
                </ListItemIcon>
                <ListItemText>Actions</ListItemText>

                <Typography style={{ display: 'inherit' }}>
                    <Icon
                        name="Arrow"
                        direction="down"
                        color={open ? 'white' : 'black'}
                    />
                </Typography>
            </MenuItem>
        </Paper>
    )

    return (
        <>
            {header}
            {open && (
                <>
                    <ActionsList
                        resource={resource}
                        resourceType={resourceType}
                        activeTab={activeTab}
                        verb={verb}
                    ></ActionsList>
                </>
            )}
        </>
    )
}

export default LeftPaneActions

import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Header, Dropdown } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useAuthState } from 'packages/core'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useCurrentPerson } from 'hooks'
import config from 'config'

const AccountDropdown = () => {
    const { t } = useTranslation()
    const [{ displayName }] = useAuthState()
    const [open, setOpen] = useState(false)

    const history = useHistory()

    const isSmallScreen = useIsSmallScreen()

    const { data, isLoading } = useCurrentPerson()

    const content = (
        <span>
            <Dropdown.Item
                onClick={() => {
                    history.push('/signout')
                    setOpen((prev) => !prev)
                }}
            >
                <Icon name="LogOut" color="#919193" />
                {t('SignOut')}
            </Dropdown.Item>
        </span>
    )

    const imageUrl = `${config.BASE_EID_URL}${data?.imageThumbUrl}`

    const mobile = (
        <Header.AccountDropdown
            loading={isLoading}
            imageUrl={imageUrl}
            anchorPosition={{ right: '-32px' }}
            showCone={false}
            open={open}
            handleOpen={setOpen}
            friendlyName={data?.friendlyName}
        >
            {content}
        </Header.AccountDropdown>
    )

    const desktop = (
        <Header.AccountDropdown
            loading={isLoading}
            imageUrl={imageUrl}
            displayName={displayName}
            open={open}
            handleOpen={setOpen}
            friendlyName={data?.friendlyName}
        >
            {content}
        </Header.AccountDropdown>
    )
    return isSmallScreen ? mobile : desktop
}

export default AccountDropdown

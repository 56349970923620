import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowSmallIcon } from 'packages/eid-icons'
import { makeStyles, createStyles, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useGreenStyles = makeStyles(
    createStyles({
        root: {
            // minWidth: '177px',
            height: '31px',

            borderRadius: '16px',
            padding: '0px 15px 0px 10px',
            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',

            backgroundColor: 'transparent',
            border: 'solid 1px #01ae8f',

            color: '#01ae8f',
            '&:hover': {
                cursor: 'hand',
                backgroundColor: '#01ae8f',
                color: 'white',
            },
        },
    }),
)
const DetailsButton = ({ item, onClick }) => {
    const greenClasses = useGreenStyles()
    const history = useHistory()
    const { t } = useTranslation()

    const [detailsButtonIconColor, setDetailsButtonIconColor] =
        useState('#01ae8f')

    return (
        <Button
            onMouseEnter={() => {
                setDetailsButtonIconColor('white')
            }}
            onMouseLeave={() => {
                setDetailsButtonIconColor('#01ae8f')
            }}
            classes={greenClasses}
            onClick={onClick}
            endIcon={
                <ArrowSmallIcon
                    color={detailsButtonIconColor}
                    direction={'right'}
                    fill={detailsButtonIconColor}
                />
            }
        >
            {t('Details')}
        </Button>
    )
}

export default DetailsButton

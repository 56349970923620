import { useState } from 'react'
import { Icon } from 'packages/eid-icons'
import { makeStyles, createStyles, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useRedStyles = makeStyles(
    createStyles({
        root: {
            position: 'relative',
            height: '31px',
            padding: '0px 15px 0px 10px',
            borderRadius: '16px',

            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',

            backgroundColor: 'transparent',
            border: 'solid 1px #ff4d5f',

            color: '#ff4d5f',
            '&:hover': {
                backgroundColor: '#ff4d5f',
                color: 'white',
                cursor: 'hand',
            },
        },
        disabled: {
            backgroundColor: '#efeff1',
            color: '#8b909a !important',
            border: 'solid 1px #8b909a'
        }
    }),
)

const DeleteButton = ({ onClick, disabled }) => {
    const { t } = useTranslation()
    const [actionsButtonIconColor, setActionsButtonIconColor] =
        useState('#ff4d5f')

    const buttonClasses = useRedStyles()

    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            onMouseEnter={() => {
                setActionsButtonIconColor('white')
            }}
            onMouseLeave={() => {
                setActionsButtonIconColor('#ff4d5f')
            }}
            classes={buttonClasses}
            endIcon={
                <Icon
                    name="DeleteX"
                    style={{ width: '6px', height: '6px' }}
                    color={disabled ? '#8b909a' : actionsButtonIconColor}
                    fill={disabled ? '#8b909a' : actionsButtonIconColor}
                />
            }
        >
            {t('Delete')}
        </Button>
    )
}

export default DeleteButton

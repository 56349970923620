import { Fragment, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Collapse, Paper } from '@material-ui/core'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { ArrowIcon } from 'packages/eid-icons'
import { useApplication } from 'hooks'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl } from 'utils'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { Skeleton } from '@material-ui/lab'
import useSubcomponents from 'useSubcomponents'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            minHeight: '500px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        imgContainer: {
            position: 'absolute',
            top: '30px',
            right: '26px',
            minWidth: '230px',
            maxWidth: '230px',

            '& img': {
                maxHeight: '92px',
                maxWidth: '146px',
            },

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                minWidth: '64px',
                '& img': {
                    maxHeight: '64px',
                    width: '64px',
                },
            },
        },
        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: '#307fc1',
        },
    }),
)

const allSections = [
    {
        name: 'applicationDetails',
        requireAccess: 'ResourceAdmin-ApplicationDetails-GeneralDetails-Column',
    },
    {
        name: 'azureApplicationDetails',
        requireAccess:
            'ResourceAdmin-AzureApplicationDetails-GeneralDetails-Column',
    },
]

export const OverviewSection = (props) => {
    const classes = useStyles()

    const { applicationId } = props

    const { data, isLoading } = useApplication(applicationId)

    const { t } = useTranslation()

    const { trimControlsBasedOnAccess } = useSubcomponents()
    const sections = trimControlsBasedOnAccess(allSections).map((f) => f.name)

    const azureApplicationDetails = data && (
        <Box>
            <Attribute>
                <AttributeLabel
                    className={classes.azureApplicationDetailsLabel}
                >
                    {t('AzureApplicationDetails')}
                </AttributeLabel>
            </Attribute>
            {data.azureApplicationFriendlyName && (
                <Attribute>
                    <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                    <AttributeValue>
                        {data.azureApplicationFriendlyName}
                    </AttributeValue>
                </Attribute>
            )}

            {data.azureApplicationDescription && (
                <Attribute>
                    <AttributeLabel>{t('Description')}</AttributeLabel>
                    <AttributeValue>
                        {data.azureApplicationDescription}
                    </AttributeValue>
                </Attribute>
            )}

            {data.azureIntegrationType && (
                <Attribute>
                    <AttributeLabel>{t('Type_Of_Integration')}</AttributeLabel>
                    <AttributeValue>{data.azureIntegrationType}</AttributeValue>
                </Attribute>
            )}

            {data.azureApplicationLine && (
                <Attribute>
                    <AttributeLabel>{t('Application_Line')}</AttributeLabel>
                    <AttributeValue>{data.azureApplicationLine}</AttributeValue>
                </Attribute>
            )}

            {data.azureUpxId && (
                <Attribute>
                    <AttributeLabel>{t('Upx_ID')}</AttributeLabel>
                    <AttributeValue
                        className={classes.azureApplicationDetailsIdValues}
                    >
                        {data.azureUpxId}
                    </AttributeValue>
                </Attribute>
            )}

            {data.azureAuthenticationLevel && (
                <Attribute>
                    <AttributeLabel>{t('Authentication_Level')}</AttributeLabel>
                    <AttributeValue>
                        {data.azureAuthenticationLevel}
                    </AttributeValue>
                </Attribute>
            )}

            {data.applicationId && (
                <Attribute>
                    <AttributeLabel>
                        {t('AzureApplicationAppId')}
                    </AttributeLabel>
                    <AttributeValue
                        className={classes.azureApplicationDetailsIdValues}
                    >
                        {data.applicationId}
                    </AttributeValue>
                </Attribute>
            )}

            {data.azureApplicationId && (
                <Attribute>
                    <AttributeLabel>
                        {t('AzureApplicationObjectId')}
                    </AttributeLabel>
                    <AttributeValue
                        className={classes.azureApplicationDetailsIdValues}
                    >
                        {data.azureApplicationId}
                    </AttributeValue>
                </Attribute>
            )}

            {data.servicePrincipleId && (
                <Attribute>
                    <AttributeLabel>{t('ServicePrincipleId')}</AttributeLabel>
                    <AttributeValue
                        className={classes.azureApplicationDetailsIdValues}
                    >
                        {data.servicePrincipleId}
                    </AttributeValue>
                </Attribute>
            )}
        </Box>
    )

    const applicationDetails = (
        <Box className={classes.basicInfo}>
            <Attribute>
                <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                <AttributeValue>{data && data.friendlyName}</AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('TechnicalName')}</AttributeLabel>
                <AttributeValue>{data && data.name}</AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('Description')}</AttributeLabel>
                <AttributeValue>{data && data.description}</AttributeValue>
            </Attribute>
        </Box>
    )

    const showApplicationDetails = sections.includes('applicationDetails')
    const showAzureApplicationDetails =
        sections.includes('azureApplicationDetails') &&
        data &&
        data.isAzureApplication

    const renderContent = () => (
        <Fragment>
            <Box>
                {showApplicationDetails && <Box>{applicationDetails}</Box>}
                {showAzureApplicationDetails && (
                    <Box marginTop="10px">{azureApplicationDetails}</Box>
                )}
            </Box>
            <Box className={classes.imgContainer}>
                <img
                    alt={data?.friendlyName}
                    src={
                        isNilOrEmpty(data?.imageUrl)
                            ? getFullImageUrl(
                                  '/webcdn/Images/AppLogos/Genric-1.png',
                              )
                            : getFullImageUrl(data?.applicationLogoImageUrl)
                    }
                />
            </Box>
        </Fragment>
    )

    return (
        <Paper className={classes.root}>
            {isLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box>{renderContent()}</Box>
                </Fragment>
            )}
        </Paper>
    )
}

import useSubcomponents from 'useSubcomponents'

export const useRouteOptions = () => {
    // const {
    //     hasAccessToRequestViewMyRequestsPage,
    //     hasAccessToItemViewMyRequestsPage,
    //     hasAccessToRequestViewMyTasksPage,
    //     hasAccessToItemViewMyTasksPage,
    //     hasAccessToRequestViewAllRequestsPage,
    //     hasAccessToItemViewAllRequestsPage,
    // } = useSubcomponents()

    const routeOptions = []
    // if (
    //     hasAccessToRequestViewMyRequestsPage ||
    //     hasAccessToItemViewMyRequestsPage
    // ) {
    routeOptions.push(
        {
            name: 'Applications',
            title: 'Applications',
            icon: 'ApplicationsSmall',
            route: '/applications',

            children: [
                {
                    title: 'View All',
                    icon: 'BusinessRolesSmall',
                    route: '/applications',
                },
                {
                    title: 'WorkFlows',
                    icon: 'ManagementRolesSmall',
                    route: '/applications/workflows',
                },
            ],
        },
        {
            name: 'Application Roles',
            title: 'ApplicationRoles',
            icon: 'ApplicationsSmall',
            route: '/groups',

            children: [
                {
                    title: 'View All',
                    icon: 'BusinessRolesSmall',
                    route: '/groups',
                },
                {
                    title: 'WorkFlows',
                    icon: 'ManagementRolesSmall',
                    route: '/groups/workflows',
                },
            ],
        },
        {
            name: 'Management Roles',
            title: 'ManagementRoles',
            icon: 'ApplicationsSmall',
            route: '/managementRoles',

            children: [
                {
                    title: 'View All',
                    icon: 'BusinessRolesSmall',
                    route: '/managementRoles',
                },
                {
                    title: 'WorkFlows',
                    icon: 'ManagementRolesSmall',
                    route: '/managementRoles/workflows',
                },
            ],
        },
    )
    // }

    return { routeOptions }
}

import React, { useReducer, createContext, useContext } from 'react'
import reducer, { initialState } from './reducer'

export const SecretsContext = createContext({})

export const SecretsContextProvider = ({ children }) => (
    <SecretsContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </SecretsContext.Provider>
)

export const useSecretsContext = () => useContext(SecretsContext)

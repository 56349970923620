import PropTypes from 'prop-types'
import { Card, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Attributes from './Attributes'

const useCardStyles = makeStyles({
    root: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
        border: 'none !important',
        borderRadius: '8px !important',
        ...props.cardStyles,
        '& .font-size-large': {
            '& > div': { fontSize: '24px' },
        },
        '& .bold': {
            '& > div > p > p': { fontWeight: 'bold' },
        },
    }),
    lineGlow: {
        width: '100%',
        height: '1px',
        opacity: 0.25,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: 1,
    },
})

const ItemCard = (props) => {
    const { item, cardStyles, attributes, color, leftButton, rightButton } =
        props

    const cardClasses = useCardStyles({ cardStyles })

    return (
        <Card classes={cardClasses} role="button">
            <Attributes item={item} attributes={attributes} color={color} />

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                margin="20px 0px"
            >
                {leftButton}
                {rightButton}
            </Box>
            <div className={cardClasses.lineGlow}></div>
        </Card>
    )
}

ItemCard.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func,
    className: PropTypes.string,
}

export default ItemCard
